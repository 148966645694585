<template>
  <div id="modal__allNotesOnBoarding">
    <div id="allNotesOnBoarding__notes-container">
      <div id="notes-wrapper">
        <div
          ref="note"
          v-for="note in getCurrentOrderNotes"
          :key="note.NoteID"
          :class="'note ' + note.NoteType"
        >
          <div class="all__notes" :class="note.NoteType + '__header'">
            <p>{{ `${note.NoteType} - ${note.FirstName} ${note.LastName}` }}</p>
            <p v-if="note.NoteUnitNumber">Note Specific To: {{ note.NoteUnitNumber }}</p>
            <p v-else>- Order Note -</p>
            <p>{{ $moment(note.CreatedOn, 'MM/DD/YYYY, h:mm A') }}</p>
          </div>
          <div class="note__body">
            <div class="cancel-note">
              <font-awesome-icon
                @click="() => handleNoteCancelling(0, note.NoteID)"
                v-if="note.NoteCancelled == 1"
                class="cancel-note__icon"
                icon="recycle"
              />
              <font-awesome-icon
                v-else
                @click="() => handleNoteCancelling(1, note.NoteID)"
                class="cancel-note__icon"
                icon="trash-alt"
              />
            </div>
            <pre :style="note.NoteCancelled == 1 ? 'text-decoration: line-through;' : ''">{{
              note.NoteSubject
            }}</pre>
          </div>
        </div>
        <span id="note-bottom" ref="noteBottom"></span>
      </div>
      <div class="notes__footer">
        <textarea
          v-model="note"
          name="note"
          placeholder="Add your message..."
          @keydown.tab.prevent="tabFriendly($event)"
        ></textarea>
        <BButton
          v-if="!spinners.submitting"
          id="add-btn"
          size="sm"
          :disabled="note === ''"
          variant="outline-primary"
          @click="handleNewNote"
          >Add Note</BButton
        >
        <div v-else id="add-btn">
          <BSpinner id="b-spinner--add-btn__spinner"></BSpinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'allNotesOnBoarding',
  data() {
    return {
      spinners: {
        submitting: false,
        cancellingNote: false,
      },
      note: '',
    };
  },
  computed: mapGetters(['getCurrentNoteOrder', 'getCurrentOrderNotes', 'getModalData']),
  methods: {
    ...mapActions(['fetchNotes', 'fetchCurrentNoteOrder']),
    onModalShown() {
      this.$refs['noteBottom'].scrollIntoView({ behavior: "smooth" });
    },
    async handleNoteCancelling(cancelValue, noteId) {
      this.spinners.cancellingNote = true;
      await axios
        .put(`${constants.BASE_URL}/api/orders/note/cancel`, {
          cancelValue,
          noteId,
        })
        .then(res => {
          this.spinners.cancellingNote = false;
          (async () => {
            await this.fetchCurrentNoteOrder(this.getCurrentNoteOrder);
            await this.fetchNotes({form: this.getCurrentNoteOrder.FormHistoryID, unit: this.getModalData.modalCurrentVin.UnitNumber});
          })();
        })
        .catch(err => {
          console.log(err);
        });
    },
    async handleNewNote(e) {
      this.spinners.submitting = true;
      await axios
        .post(`${constants.BASE_URL}/api/orders/note/new`, {
          note: this.note,
          FormHistoryID: this.getCurrentNoteOrder.FormHistoryID,
          department: this.$route.name.toLowerCase(),
          unitNumber: ['accounts-receivable', 'recon', 'sales', 'titles'].includes(this.$route.name)
            ? this.getModalData.modalCurrentVin.UnitNumber
            : null,
        })
        .then(res => {
          this.note = '';
          this.spinners.submitting = false;
        })
        .catch(err => {
          this.spinners.submitting = false;
          console.log(err);
        });
      await this.fetchNotes({form: this.getCurrentNoteOrder.FormHistoryID, unit: this.getModalData.modalCurrentVin.UnitNumber});
      this.$socket.emit('offBoardingCardsRefresh', 'user');
      this.$socket.emit('onBoardingCardsRefresh', 'user');
      this.$socket.emit('transportCardsRefresh', 'user');
      this.$socket.emit('arOpenCardsRefresh', 'user');
      this.$socket.emit('arOffRentCardsRefresh', 'user');
      this.$socket.emit('crCardsRefresh', 'user');
      this.$socket.emit('reconCardsRefresh', 'user');
      this.$socket.emit('salesCardsRefresh', 'user');
      this.$socket.emit('titlesCardsRefresh', 'user');
    },
    tabFriendly(event) {
      let text = this.note,
        originalSelectionStart = event.target.selectionStart,
        textStart = text.slice(0, originalSelectionStart),
        textEnd = text.slice(originalSelectionStart);

      text = `${textStart}\t${textEnd}`;
      event.target.value = text;
      event.target.selectionEnd = event.target.selectionStart = originalSelectionStart + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__allNotesOnBoarding {
  min-height: 100%;
  min-width: 100%;
}

#allNotesOnBoarding__ttl {
  margin: 10px 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

.all__notes {
  text-transform: capitalize;
}

#allNotesOnBoarding__notes-container {
  width: 90%;
  margin: 0 auto;
}

#notes-wrapper {
  border-radius: 4px;
  width: 100%;
  height: 40vh;
  border: 1px solid #dee2e6;
  margin-bottom: 10px;

  padding: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px $barcoLightBlueFont;
    box-shadow: inset 0 0 10px $barcoLightBlueFont;
  }
}

// - ========== DYNAMIC STYLES BELOW ==========
.onboarding {
  background: #f8b2b23b;
}
.onboarding__header {
  background: #fc494960;
  padding: 5px 10px;
}

.transport {
  background: #a7c1df5d;
}
.transport__header {
  background: #3582da5b;
  padding: 5px 10px;
}

.offboarding {
  background: #d6bae449;
}
.offboarding__header {
  background: #c375e762;
  padding: 5px 10px;
}

.accounts-receivable {
  background: #e2a5d349;
}
.accounts-receivable__header {
  background: #e609af2a;
  padding: 5px 10px;
}

.client-relations {
  background: #86868649;
}
.client-relations__header {
  background: #2727271c;
  padding: 5px 10px;
}

.recon {
  background: #dae0d688;
}
.recon__header {
  background: #7ab85ebe;
  padding: 5px 10px;
}

.sales {
  background: #ecddba7a;
}
.sales__header {
  background: #d4a43ca9;
  padding: 5px 10px;
}

.titles {
  background: #a5d7e657;
}
.titles__header {
  background: #66cddf6b;
  padding: 5px 10px;
}

// - ========== DYNAMIC STYLES ABOVE ==========

.note__body {
  position: relative;
  padding: 10px 10px;
  h5 {
    width: 100%;
    word-wrap: break-word;
  }
  pre {
    max-width: 560px;
    font-size: 0.7rem;
    font-weight: 600;
    color: var(--notes-color);
    font-family: sans-serif;

    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
.cancel-note {
  position: absolute;
  right: 4px;
  top: 3px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  transition: background-color 100ms;
  &:hover {
    background-color: rgba(153, 153, 153, 0.198);

    .cancel-note__icon {
      color: rgb(110, 110, 110);
    }
  }
  .cancel-note__icon {
    margin-left: 3px;
    height: 13px;
    color: gray;
    transition: color 100ms;
  }
}

.note {
  width: 100%;
  margin: 0 0 10px;
  // overflow: hidden;
  border-radius: 0.4rem;
  div {
    @include F($j: space-between);
    p {
      font-size: 0.7rem;
      font-weight: 600;
      color: var(--notes-color);

      // text-shadow: 1px 1px 2px rgba(94, 0, 0, 0.623);
    }
  }
  h5 {
    font-size: 0.9rem;
    color: var(--notes-color);
    letter-spacing: 0.4px;
  }
}

.notes__footer {
  height: 14vh;
  width: 100%;
  @include F($d: column);
  textarea {
    outline: none;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    font-size: 0.9rem;
    color: var(--card-text-color);
    font-weight: 500;
    padding: 5px 10px;
    resize: none;
    width: 100%;
    height: 90%;
    margin-bottom: 10px;
  }
  #add-btn {
    margin: 5px 0 10px auto;
  }

  #b-spinner--add-btn__spinner {
    color: #5aaaff;
  }
}
</style>
