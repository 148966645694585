<template>
	<div id="modal__splitTrade">
		<BRow align-h="between" class="card_split_container">
			<div v-for="(split, i) in Splits" :key="split.SplitNumber" class="split-card-width">
  			<div class="boarding-card-container">
					<div class="top-info-container">
						<div class="boarding-card width-100">
							<div class="split-remove-container">
								<font-awesome-icon
								class="split-remove"
								icon="minus-circle"
								v-if="i != 0"
								@click="removeSplit(i)"
								/>
							</div>
							<i class="boarding-card__ttl"
								>{{ order.CorporateNameTrade }}</i
							>
							<div class="width-50">
								<h6>New Remaining:
									<!-- <font-awesome-icon v-if="split.Reservations.length < split.Remaining && split.Remaining > 1" class="subtract-count" icon="minus-circle" /> -->
									<b>{{ split.Remaining }}</b>
									<!-- <font-awesome-icon v-if="Splits.length != 1 && !MaxReserves && order.NewTrucksRemaining !== order.ReturnTrucksRemaining" class="add-count" icon="plus-circle" @click="addCount(i)" /> -->
								</h6>
							</div>
							<div class="width-50">
								<h6>Returns Remaining: 
									<b>{{ split.Returning }}</b>
								</h6>
							</div>
							<div class="width-50">
								<h6>1/2 Tons: 
									<font-awesome-icon v-if="!SingleTruckCount && split.Half >= 1" class="subtract-count" icon="minus-circle" @click="minusHalf(i)" />
									<b>{{ split.Half }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.HalfTonCountTrade != 0 && split.Half < order.HalfTonCountTrade" class="add-count" icon="plus-circle" @click="addHalf(i)" />
								</h6>
							</div>
							<div class="width-50">
								<h6>3/4 Tons: 
									<font-awesome-icon v-if="!SingleTruckCount && split.ThreeQ >= 1" class="subtract-count" icon="minus-circle" @click="minusThreeQ(i)" />
									<b>{{ split.ThreeQ }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.ThreeQuarterTonCountTrade != 0 && split.ThreeQ < order.ThreeQuarterTonCountTrade" class="add-count" icon="plus-circle" @click="addThreeQ(i)" />
								</h6>
							</div>
							<div class="width-50">
								<h6>Flatbeds: 
									<font-awesome-icon v-if="!SingleTruckCount && split.Flat >= 1" class="subtract-count" icon="minus-circle" @click="minusFlat(i)" />
									<b>{{ split.Flat }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.OneTonFlatCountTrade != 0 && split.Flat < order.OneTonFlatCountTrade" class="add-count" icon="plus-circle" @click="addFlat(i)" />
								</h6>
							</div>
							<div class="width-50">
								<h6>Service Body: 
									<font-awesome-icon v-if="!SingleTruckCount && split.Service >= 1" class="subtract-count" icon="minus-circle" @click="minusService(i)" />
									<b>{{ split.Service }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.OneTonServiceCountTrade != 0 && split.Service < order.OneTonServiceCountTrade" class="add-count" icon="plus-circle" @click="addService(i)" />
								</h6>
							</div>
							<div class="width-100">
								<h6>Total on Order: <b>{{ split.Total }}</b></h6>
							</div>
							<div class="width-50"><h6>Reservations: <b>{{ split.Reservations.length }}</b></h6></div>
							<div class="width-50"><h6>Returning: <b>{{ split.OffRents.length }}</b></h6></div>
							<VueDraggable 
								v-model="split.Reservations" 
								group="reserve"
								@start="drag=true" 
								@end="dropped"
								:move="checkMoveOn"
								ghostClass='ghost'
								:id="i"
								class="drag-zone-left">
								<div v-for="unit in split.Reservations" :key="unit.ReservedUnit" class="width-50">
									<BButton variant="secondary" class="draggable-buttons">{{ unit.ReservedUnit }}</BButton>
								</div>
							</VueDraggable>
							<VueDraggable 
								v-model="split.OffRents" 
								group="return"
								@start="drag=true" 
								@end="droppedOff"
								:move="checkMoveOff"
								ghostClass='ghost'
								:id="i"
								class="drag-zone-right">
								<div v-for="unit in split.OffRents" :key="unit.OffRent" class="width-50">
									<BButton :variant="unit.OnComplete ? 'danger' : 'warning'" class="draggable-buttons">{{ unit.OffRent }}</BButton>
								</div>
							</VueDraggable>
						</div>
					</div>
				</div>
			</div>
			<div v-if="OriginalRemaining > 0 && OriginalRequested > 1" class="split-card-width">
  			<div class="boarding-card-container">
					<div class="top-info-container">
						<div class="add-split-card width-100">
							<div class="load-more-btn" @click="addSplit()">
								<p class="plus-btn plus-btn-split">+</p>
								<p class="load-more-text add-split-text">Add Split</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BRow><br>
		<BRow align-h="between" class="width-100">
			<BCol cols="auto">
				<BButton variant="outline-secondary float-start" @click="$emit('closeModal')">Cancel</BButton>
			</BCol>
			<BCol cols="auto">
				<BSpinner v-if="Splitting">Splitting...</BSpinner>
				<BButton v-else variant="outline-primary float-end" @click="confirmSplit">Split</BButton>
			</BCol>
		</BRow>
		<BRow v-if="splitError">
			<h6 class="red">{{ splitError }}</h6>
		</BRow>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import constants from '@/assets/constants';
import { VueDraggable } from 'vue-draggable-plus';
// import EventBus from '../../utils/helpers/eventBus';

export default {
  props: ['order'],
	components: {
		VueDraggable,
	},
	name: 'splitTradeModal',
	data() {
		return {
			Splits: [{
				SplitNumber: 0,
				Remaining: this.order.NewTrucksRemaining,
				Returning: this.order.ReturnTrucksRemaining,
				Total: this.order.NumTrucksReturning,
				Half: this.order.HalfTonCountTrade,
				ThreeQ: this.order.ThreeQuarterTonCountTrade,
				Flat: this.order.OneTonFlatCountTrade,
				Service: this.order.OneTonServiceCountTrade,
				Reservations: [],
				OffRents: [],
			}],
			OriginalRemaining: this.order.NewTrucksRemaining,
			OriginalRequested: this.order.NumTrucksReturning,
			MaxReserves: false,
			Splitting: false,
			splitError: '',
			SingleTruckCount: false,
			TruckType: '',
		};
	},
	computed: mapGetters(['getCurrentNoteOrder']),
	created() {
		axios
			.get(`${constants.BASE_URL}/api/getTradeReservations/` + this.order.FormHistoryID, {
				headers: { Authorization: localStorage.getItem('token') },
			})
			.then(result => {
				for (let i = 0; i < result.data.length; i++) {
					this.Splits[0].Reservations.push( 
					{
						ReservedUnit: result.data[i].UnitNumber,
						FormHistoryID: result.data[i].FormHistoryID,
						TradeFormID: result.data[i].TradeFormID,
						SplitNumber: 0
					});
				}
				if (result.data.length >= this.OriginalRemaining) {
					this.MaxReserves = true;
				}
			}).catch(err => {
				console.log('error: ', err);
			});
		if (this.order.NumTrucksReturning == this.order.HalfTonCountTrade ||
		this.order.NumTrucksReturning == this.order.ThreeQuarterTonCountTrade ||
		this.order.NumTrucksReturning == this.order.OneTonFlatCountTrade ||
		this.order.NumTrucksReturning == this.order.OneTonServiceCountTrade) {
			this.SingleTruckCount = true;
			if (this.order.HalfTonCountTrade != 0) {
				this.TruckType = 'Half';
			} else if (this.order.ThreeQuarterTonCountTrade != 0) {
				this.TruckType = 'ThreeQ';
			} else if (this.order.OneTonFlatCountTrade != 0) {
				this.TruckType = 'Flat';
			} else if (this.order.OneTonServiceCountTrade != 0) {
				this.TruckType == 'Service';
			}
		}
		let offList = this.order.TruckTrade.split(',')
		for (let i = 0; i < offList.length; i++) {
			if (offList[i].split(' - ')[2] == 'unassigned') {
				this.Splits[0].OffRents.push({
					OffRent: offList[i].split(' - ')[0],
					OnComplete: this.order.TradeLink && this.order.TradeLink.includes(offList[i].split(' - ')[0]) ? true : false
			});
			}
		}
	},
	methods: {
		...mapActions(['closeModal', 'fetchAllOrders']),
		addSplit() {
			let lastReserve = [];
			let lastOff = [];
			let half = 0;
			let threeQ = 0;
			let flat = 0;
			let service = 0;
			let completedOn = false;
			for (let i = 0; i < this.Splits.length; i++) {
				completedOn = false;
				if (this.Splits[i].Returning > 0) {
					if (this.Splits[i].OffRents[this.Splits[i].OffRents.length - 1].OnComplete == false) {
						this.Splits[i].Remaining--;
						completedOn = true;
					} else if (this.Splits[i].Reservations.length > 1) {
						this.Splits[i].Remaining = this.Splits[i].Remaining - 1;
						lastReserve.push(this.Splits[i].Reservations.pop());
						lastReserve[0].SplitNumber = this.Splits[i].SplitNumber;
					} else if (this.order.NewTrucksRemaining == this.order.ReturnTrucksRemaining) {
						this.Splits[i].Remaining--;
					}
					if (this.Splits[i].OffRents.length > 0) {
						this.Splits[i].Returning = this.Splits[i].Returning - 1;
						this.Splits[i].Total = this.Splits[i].Total - 1;
						lastOff.push(this.Splits[i].OffRents.pop());
						lastOff[0].SplitNumber = this.Splits[i].SplitNumber;
					}
					if (this.SingleTruckCount) {
						if (this.TruckType == 'Half') {
							this.Splits[i].Half--;
							half = 1;
						} else if (this.TruckType == 'ThreeQ') {
							this.Splits[i].ThreeQ--;
							threeQ = 1;
						} else if (this.TruckType == 'Flat') {
							this.Splits[i].Flat--;
							flat = 1;
						} else if (this.TruckType == 'Service') {
							this.Splits[i].Service--;
							service = 1;
						}
					}
					// Don't keep removing remaining after first one found available
					if (lastOff.length > 0) {
						break;
					}
				} 
			}
			this.Splits.push({
				SplitNumber: this.Splits[this.Splits.length - 1].SplitNumber + 1,
				Remaining: this.order.NewTrucksRemaining == this.order.ReturnTrucksRemaining || completedOn == true ? 1 : lastReserve.length,
				Returning: lastOff.length,
				Total: lastOff.length,
				Half: half,
				ThreeQ: threeQ,
				Flat: flat,
				Service: service,
				Reservations: lastReserve,
				OffRents: lastOff,
			});
			this.OriginalRemaining--;
			this.OriginalRequested--;
		},
		removeSplit(i) {
			let halfRemain = 0;
			let threeQRemain = 0;
			let flatRemain = 0;
			let serviceRemain = 0;
			for (let x = 1; x < this.Splits.length; x++) {
				if (x !== i) {
					halfRemain += this.Splits[x].Half
					threeQRemain += this.Splits[x].ThreeQ;
					flatRemain += this.Splits[x].Flat;
					serviceRemain += this.Splits[x].Service;
				}
			}
			this.Splits[0].Remaining += this.Splits[i].Remaining;
			this.Splits[0].Returning += this.Splits[i].Returning;
			this.Splits[0].Total += this.Splits[i].Total;
			this.Splits[0].Reservations = this.Splits[0].Reservations.concat(this.Splits[i].Reservations);
			this.Splits[0].OffRents = this.Splits[0].OffRents.concat(this.Splits[i].OffRents);
			this.Splits[0].Half = this.order.HalfTonCountTrade - halfRemain;
			this.Splits[0].ThreeQ = this.order.ThreeQuarterTonCountTrade - threeQRemain;
			this.Splits[0].Flat = this.order.OneTonFlatCountTrade - flatRemain;
			this.Splits[0].Service = this.order.OneTonServiceCountTrade - serviceRemain;
			this.Splits.splice(i, 1);
			this.OriginalRemaining++;
			this.OriginalRequested++;
		},
		async confirmSplit() {
			this.Splitting = true;
			for (let i = 0; i < this.Splits.length; i++) {
				if (this.Splits[i].Total == 0) {
					this.Splitting = false;
					this.splitError = 'Cannot split order with 0 Trucks on it.';
          setTimeout(() => (this.splitError = ''), 10000);
					return;
				} else if ((this.Splits[i].Half + this.Splits[i].ThreeQ + this.Splits[i].Flat + this.Splits[i].Service) != this.Splits[i].Total) {
					this.Splitting = false;
					this.splitError = 'Please make sure "1/2 Tons + 3/4 Tons + Flatbeds + Service Body" count matches Total on Order for all split orders.';
          setTimeout(() => (this.splitError = ''), 10000);
					return;
				} else if (this.Splits[i].Remaining > this.Splits[i].Returning) {
					this.Splitting = false;
					this.splitError = 'Cannot have more New Remaining trucks than Returns Remaining on a Trade.';
          setTimeout(() => (this.splitError = ''), 10000);
					return;
				} else if (this.Splits[i].Remaining < this.Splits[i].Reservations.length) {
					this.Splitting = false;
					this.splitError = 'Cannot have more Reservations than New Remaining trucks on a Trade.';
          setTimeout(() => (this.splitError = ''), 10000);
					return;
				}
			}
			if (this.Splits.length > 1) {
				await axios
        .put(`${constants.BASE_URL}/api/split-trade`, {
					Splits: this.Splits,
					OgTrade: this.order,
				})
        .then(res => {
					this.Splitting = false;
					// EventBus.emit('splitTradeComplete');
					this.$socket.emit('transportCardsRefresh');
					this.$socket.emit('onBoardingCardsRefresh');
          // this.closeModal();
          this.$emit('closeModal');
        })
        .catch(err => {
					console.log(err);
					this.splitError = 'Unable to complete split.';
          setTimeout(() => (this.splitError = ''), 6000);
        });
			} else {
				this.splitError = 'Must have at least one split.';
				this.Splitting = false;
				setTimeout(() => (this.splitError = ''), 6000);
			}
		},
		dropped(evt) {
			this.drag = false;
			// if (this.Splits[evt.to.id].Reservations.length > this.Splits[evt.to.id].Remaining) {
			// 	if (this.Splits[evt.to.id].Reservations.length != 0) {
			// 		this.Splits[evt.to.id].Remaining++;
			// 	}
			// 	if (this.Splits[evt.from.id].Remaining > 1 || this.order.NewTrucksRemaining != this.order.NumTrucksReturning) {
			// 		this.Splits[evt.from.id].Remaining--;
			// 	}
				// if (this.SingleTruckCount) {
				// 	if (this.TruckType == 'Half') {
				// 		this.Splits[evt.from.id].Half--;
				// 		this.Splits[evt.to.id].Half++;
				// 	} else if (this.TruckType == 'ThreeQ') {
				// 		this.Splits[evt.from.id].ThreeQ--;
				// 		this.Splits[evt.to.id].ThreeQ++;
				// 	} else if (this.TruckType == 'Flat') {
				// 		this.Splits[evt.from.id].Flat--;
				// 		this.Splits[evt.to.id].Flat++;
				// 	} else if (this.TruckType == 'Service') {
				// 		this.Splits[evt.from.id].Service--;
				// 		this.Splits[evt.to.id].Service++;
				// 	}
				// }
			// }
			// if (this.Splits[evt.from.id].Remaining > this.Splits[evt.from.id].OffRents.length) {
			// 	this.Splits[evt.from.id].Remaining--;
			// }
			// if (this.Splits[evt.to.id].Remaining < this.Splits[evt.to.id].OffRents.length) {
			// 	this.Splits[evt.to.id].Remaining++;
			// }
		},
		droppedOff(evt) {
			this.drag = false;
			if (this.Splits[evt.to.id].OffRents.length > this.Splits[evt.to.id].Returning) {
				if (this.Splits[evt.to.id].OffRents.length != 0) {
					this.Splits[evt.to.id].Returning++;
					this.Splits[evt.to.id].Total++;
					if (this.order.NewTrucksRemaining == this.order.ReturnTrucksRemaining || this.Splits[evt.to.id].OffRents[evt.newIndex].OnComplete == false) {
						this.Splits[evt.to.id].Remaining++;
					}
				}
				if (this.Splits[evt.from.id].Returning > 1 || this.order.ReturnTrucksRemaining != this.order.NumTrucksReturning) {
					this.Splits[evt.from.id].Returning--;
					this.Splits[evt.from.id].Total--;
					if (this.order.NewTrucksRemaining == this.order.ReturnTrucksRemaining || this.Splits[evt.to.id].OffRents[evt.newIndex].OnComplete == false) {
						this.Splits[evt.from.id].Remaining--;
					}
				}
			}
			if (this.SingleTruckCount) {
				if (this.TruckType == 'Half') {
					this.Splits[evt.from.id].Half--;
					this.Splits[evt.to.id].Half++;
				} else if (this.TruckType == 'ThreeQ') {
					this.Splits[evt.from.id].ThreeQ--;
					this.Splits[evt.to.id].ThreeQ++;
				} else if (this.TruckType == 'Flat') {
					this.Splits[evt.from.id].Flat--;
					this.Splits[evt.to.id].Flat++;
				} else if (this.TruckType == 'Service') {
					this.Splits[evt.from.id].Service--;
					this.Splits[evt.to.id].Service++;
				}
			}
		},
		addCount(i) {
			let half = 0;
			let threeQ = 0;
			let flat = 0;
			let service = 0;
			if (i == 0) {
				for (let x = 1; x < this.Splits.length; x++) {
					if (this.Splits[x].Remaining > this.Splits[x].Reservations.length && this.Splits[x].Remaining > 1) {
						this.Splits[x].Remaining--;
						// this.Splits[x].Total--;
						this.Splits[i].Remaining++;
						// this.Splits[i].Total++;
						// if (this.SingleTruckCount) {
						// 	if (this.TruckType == 'Half') {
						// 		this.Splits[x].Half--;
						// 		this.Splits[i].Half++;
						// 	} else if (this.TruckType == 'ThreeQ') {
						// 		this.Splits[x].ThreeQ--;
						// 		this.Splits[i].ThreeQ++;
						// 	} else if (this.TruckType == 'Flat') {
						// 		this.Splits[x].Flat--;
						// 		this.Splits[i].Flat++;
						// 	} else if (this.TruckType == 'Service') {
						// 		this.Splits[x].Service--;
						// 		this.Splits[i].Service++;
						// 	}
						// }
						break;
					}
				}
			} else if (this.Splits[0].Remaining > this.Splits[0].Reservations.length && this.Splits[0].Total >= 1) {
				// console.log('resevations: ', this.Splits[0].Reservations.length);
				// console.log('remaining: ', this.Splits[0].Remaining)
				this.Splits[0].Remaining--;
				// this.Splits[0].Total--;
				this.Splits[i].Remaining++;
				// this.Splits[i].Total++;
				// if (this.SingleTruckCount) {
				// 	if (this.TruckType == 'Half') {
				// 		this.Splits[0].Half--;
				// 		this.Splits[i].Half++;
				// 	} else if (this.TruckType == 'ThreeQ') {
				// 		this.Splits[0].ThreeQ--;
				// 		this.Splits[i].ThreeQ++;
				// 	} else if (this.TruckType == 'Flat') {
				// 		this.Splits[0].Flat--;
				// 		this.Splits[i].Flat++;
				// 	} else if (this.TruckType == 'Service') {
				// 		this.Splits[0].Service--;
				// 		this.Splits[i].Service++;
				// 	}
				// }
			}
		},
		checkMoveOn: function(evt) {
			let counter = 0;
			if (this.MaxReserves == true && this.Splits[evt.from.id].Reservations.length == 1) {
				if (this.order.NewTrucksRemaining == this.order.NumTrucksReturning) {
					return false;
				}
			}
			for (let i = 0; i < this.Splits[evt.to.id].OffRents.length; i++) {
				if (this.Splits[evt.to.id].OffRents[i].OnComplete == true) {
					counter++;
				}
			};
			if (this.Splits[evt.to.id].Returning - counter < 1) {
				return false;
			}
    },
		checkMoveOff: function(evt) {
			if (this.Splits[evt.from.id].OffRents.length == 1) {
				if (this.order.ReturnTrucksRemaining == this.order.NumTrucksReturning) {
					return false;
				}
			}
    },
		addHalf(i) {
			this.Splits[i].Half++;
		},
		addThreeQ(i) {
			this.Splits[i].ThreeQ++;
		},
		addFlat(i) {
			this.Splits[i].Flat++;
		},
		addService(i) {
			this.Splits[i].Service++;
		},
		minusHalf(i) {
			this.Splits[i].Half--;
		},
		minusThreeQ(i) {
			this.Splits[i].ThreeQ--;
		},
		minusFlat(i) {
			this.Splits[i].Flat--;
		},
		minusService(i) {
			this.Splits[i].Service--;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__splitTrade {
	min-width: 100%;
	min-height: 100%;
	@include F($d: column);
}
.card_split_container {
  width: 90%;
  min-height: 65vh;
  @include F($j: flex-start);
  flex-wrap: wrap;
  position: relative;
}
.split-card-width {
	width: 45%;
}
.boarding-card-container {
  position: relative;
}
.boarding-card {
	background-color: var(--table-static-tr);
}
.width-50 {
	width: 50%;
}
.drag-zone-left {
	min-width: 180px;
	max-width: calc(50% - 30px);
	min-height: 200px;
	max-height: 365px;
	margin-right: 30px;
	border: 1px solid lightgray;
	overflow-y: scroll;
}
.drag-zone-right {
	min-width: 180px;
	max-width: calc(50% - 30px);
	min-height: 200px;
	max-height: 365px;
	border: 1px solid lightgray;
	overflow-y: scroll;
}
.red {
	color: red;
}
.split-remove {
	color: red;
	margin-right: 10px;
	cursor: pointer;
}
.split-remove-container {
	width: 100%;
	position: absolute;
	text-align: right;
}
.subtract-count {
	font-size: .9rem;
	margin-right: 4px;
	margin-left: 2px;
	cursor: pointer;
}
.add-count {
	font-size: .9rem;
	margin-right: 2px;
	margin-left: 4px;
	cursor: pointer;
}
.draggable-buttons {
	margin: 5px;
}
::-webkit-scrollbar{
	width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 20px;
  border: 6px solid transparent;
}

::-webkit-scrollbar-thumb:hover{
	background-color: #909090;
}
</style>
  