<template>
  <div id="modal__statusDetails">
    <BRow align-h="between">
      <BCol xs="12" sm="4">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </BCol>
      <BCol sm="3">
        <h4>{{ currentStatus }}</h4>
      </BCol>
      <BCol sm="2">
        <button @click.stop="() => exportDataToCSV('status-details-table', 'StatusDetails')" class="export-excel__btn">
          Download Excel <font-awesome-icon icon="file-csv" />
        </button>
      </BCol>
      <BCol xs="6" sm="3" md="1" class="perPageDropdown">
        <b-dropdown
          id="per-page-dropdown"
          text="Per Page"
          variant="outline-primary"
        >
          <b-dropdown-item id="perPage25" active @click="showPerPage(25)">25</b-dropdown-item>
          <b-dropdown-item id="perPage50" @click="showPerPage(50)">50</b-dropdown-item>
          <b-dropdown-item id="perPage100" @click="showPerPage(100)">100</b-dropdown-item>
          <b-dropdown-item id="perPageAll" @click="showPerPage('All')">All</b-dropdown-item>
        </b-dropdown>
      </BCol>
    </BRow>
    <b-table
      responsive
      striped
      bordered
      small
      hover
      show-empty
      :items="statusDetails"
      :fields="permissions && permissions.includes('set-rental-use') && rentalUseOverride.includes(getUserInfo.UserID) && 
        (currentStatus == 'NEW' || currentStatus == 'RE-RENT' || currentStatus == 'NEW & RE-RENT')  ? 
          StatusDetailNewExecFields : currentStatus == 'NEW' || currentStatus == 'RE-RENT' || currentStatus == 'NEW & RE-RENT' ? 
          permissions && (permissions.includes('lot-and-auction') || permissions.includes('new-trade-counts')) ? 
          StatusDetailNewFields : StatusDetailNewOnlyFields : StatusDetailFields"
      :per-page="perPage"
      :current-page="currentPage"
      id="status-details-table"
    >
    <!-- Bryce, Jason, Brandon, Tanner, Rod, Jeff -->
      <div slot="table-busy" class="text-center text-danger my-2">
        <BSpinner class="align-middle"></BSpinner>
        <strong> Loading...</strong>
      </div>
      <template v-slot:cell(OverrideLate)="data">
        <span v-if="((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
          currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
          ||
          ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
          currentStatus == 'BODY SHOP')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
          ||
          ((currentStatus == 'Hold for Sale')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
          ||
          ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
          currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
          ||
          ((currentStatus == 'Claims')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000))">
          <div v-if="data.item.OverrideLate == 1">
            {{data.item.OverrideReason}}
          </div>
        </span>
      </template>
      <template v-slot:cell(UnitNumber)="data">
        <span :class="data.item.OverrideLate != 1 && 
          (((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
          currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
          ||
          ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
          currentStatus == 'BODY SHOP')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
          ||
          ((currentStatus == 'Hold for Sale')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
          ||
          ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
          currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
          ||
          ((currentStatus == 'Claims')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000)))
        ? 'red' : ''">{{ data.item.UnitNumber }}</span>
      </template>
      <template v-slot:cell(Description)="data">
        <span :class="data.item.OverrideLate != 1 && 
          (((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
          currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
          ||
          ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
          currentStatus == 'BODY SHOP')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
          ||
          ((currentStatus == 'Hold for Sale')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
          ||
          ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
          currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
          ||
          ((currentStatus == 'Claims')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000)))
        ? 'red' : ''">{{ data.item.Description }}</span>
      </template>
      <template v-slot:cell(TruckStatusDescription)="data">
        <span :class="data.item.OverrideLate != 1 && 
          (((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
          currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
          ||
          ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
          currentStatus == 'BODY SHOP')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
          ||
          ((currentStatus == 'Hold for Sale')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
          ||
          ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
          currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
          ||
          ((currentStatus == 'Claims')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000)))
        ? 'red' : ''">{{ data.item.TruckStatusDescription }}</span>
      </template>
      <template v-slot:cell(SubStatus)="data">
        <span :class="data.item.OverrideLate != 1 && 
          (((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
          currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
          ||
          ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
          currentStatus == 'BODY SHOP')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
          ||
          ((currentStatus == 'Hold for Sale')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
          ||
          ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
          currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
          ||
          ((currentStatus == 'Claims')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000)))
        ? 'red' : ''">{{ data.item.SubStatus }}</span>
      </template>
      <template v-slot:cell(TruckNotes)="data">
        <span :class="data.item.OverrideLate != 1 && 
          (((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
          currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
          ||
          ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
          currentStatus == 'BODY SHOP')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
          ||
          ((currentStatus == 'Hold for Sale')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
          ||
          ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
          currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
          ||
          ((currentStatus == 'Claims')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000)))
        ? 'red' : ''">{{ data.item.TruckNotes }}</span>
      </template>
      <template v-slot:cell(ChangeDate)="data">
        <span :class="data.item.OverrideLate != 1 && 
          (((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
          currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
          ||
          ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
          currentStatus == 'BODY SHOP')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
          ||
          ((currentStatus == 'Hold for Sale')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
          ||
          ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
          currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
          ||
          ((currentStatus == 'Claims')
          && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000)))
        ? 'red' : ''">{{ $moment(data.item.ChangeDate, 'MM/DD/YY') }}</span>
      </template>
      <template v-slot:cell(RentalUse)="data" class="rentalUse" v-if="permissions && (permissions.includes('new-trade-counts') || permissions.includes('lot-and-auction'))">
          <span :class="data.item.OverrideLate != 1 && 
            (((currentStatus == 'Sold Not Delivered' || currentStatus == 'IN TRANSIT' || 
            currentStatus == 'IN TRANSIT (SELLER)' || currentStatus == 'ON LOT')
            && new Date(data.item.ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
            ||
            ((currentStatus == 'NEW' || currentStatus == 'OFF RENT' || currentStatus == 'RE-RENT' || 
            currentStatus == 'BODY SHOP')
            && new Date(data.item.ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
            ||
            ((currentStatus == 'Hold for Sale')
            && new Date(data.item.ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
            ||
            ((currentStatus == 'At Auction' || currentStatus == 'In Maintenance' || 
            currentStatus == 'FOR SALE' || currentStatus == 'RETAIL')
            && new Date(data.item.ChangeDate) < new Date().getTime() - (28*24*60*60*1000))  
            ||
            ((currentStatus == 'Claims')
            && new Date(data.item.ChangeDate) < new Date().getTime() - (56*24*60*60*1000)))
          ? 'red' : ''">
            <div v-if="data.item.RentalUse" class="margin-right-10">
              <BRow align-h="between">
                <BCol sm="6" class="pad-top-6">
                  {{data.item.RentalUse}}
                </BCol>
                <BCol sm="6" v-if="permissions && permissions.includes('set-rental-use') && rentalUseOverride.includes(getUserInfo.UserID)">
                  <BButton @click="setRentalUse(data.item.UnitNumber, '')" variant="danger">Undo</BButton>
                </BCol>
              </BRow>
            </div>
            <div v-else-if="permissions && permissions.includes('set-rental-use') && rentalUseOverride.includes(getUserInfo.UserID)" class="margin-right-10">
              <BRow align-h="between">
                <BCol sm="6">
                  <BButton @click="setRentalUse(data.item.UnitNumber, 'Rental')" variant='primary'>Rental</BButton>
                </BCol>
                <BCol sm="6">
                  <BButton @click="setRentalUse(data.item.UnitNumber, 'Trade')" variant="warning">Trade</BButton>
                </BCol>
              </BRow>
            </div>
            <div v-else>
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']"
              v-tooltip="{content: 'This unit has been in ' + currentStatus + ' status too long.'}"/>
            </div></span>
        </template>
    </b-table>
    <div v-if="stillLoadingStatusDetails == true">
      <div slot="table-busy" class="text-center text-danger my-2">
        <BSpinner class="align-middle"></BSpinner>
        <strong> Loading...</strong>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';
import auth from '../../utils/auth/permissionRules';
// import Vue from 'vue';
// import VTooltip from 'v-tooltip';
import { mapActions, mapGetters } from 'vuex';

// Vue.use(VTooltip)
export default {
  name: 'status-details',
  data() {
    return {
      StatusDetailFields: [
        { key: 'OverrideLate', label: 'Late', sortable: false},
        { key: 'UnitNumber', label: 'Unit', sortable: true },
        { key: 'Description', label: 'Description', sortable: true },
        { key: 'TruckStatusDescription', label: "Status", sortable: false },
        { key: 'SubStatus', sortable: true },
        { key: 'TruckNotes', label: 'Notes', sortable: true },
        { key: 'ChangeDate', label: 'Changed', sortable: true }
      ],
      StatusDetailNewFields: [
        { key: 'UnitNumber', sortable: true },
        { key: 'TruckYear', label: 'Year', sortable: true },
        { key: 'TruckMake', label: 'Make', sortable: true},
        { key: 'TruckModel', label: 'Model', sortable: true },
        { key: 'TruckColor', label: 'Color', sortable: true },
        { key: 'TruckStatusDescription', label: 'Status', sortable: true },
        { key: 'SubStatus', sortable: true },
        { key: 'Reserved', sortable: true },
        { key: 'ReservedFor', sortable: true },
        { key: 'ReservedOn', sortable: true },
        { key: 'TruckNotes', sortable: true },
        { key: 'ChangeDate', label: 'Last Changed On', sortable: true },
        { key: 'RentalUse', label: 'Rental Use', sortable: true }
      ],
      StatusDetailNewOnlyFields: [
        { key: 'UnitNumber', sortable: true },
        { key: 'TruckYear', label: 'Year', sortable: true },
        { key: 'TruckMake', label: 'Make', sortable: true},
        { key: 'TruckModel', label: 'Model', sortable: true },
        { key: 'TruckColor', label: 'Color', sortable: true },
        { key: 'TruckStatusDescription', label: 'Status', sortable: true },
        { key: 'SubStatus', sortable: true },
        { key: 'Reserved', sortable: true },
        { key: 'ReservedFor', sortable: true },
        { key: 'ReservedOn', sortable: true },
        { key: 'TruckNotes', sortable: true },
        { key: 'ChangeDate', label: 'Last Changed On', sortable: true }
      ],
      StatusDetailNewExecFields: [
        { key: 'UnitNumber', sortable: true },
        { key: 'TruckYear', label: 'Year', sortable: true },
        { key: 'TruckMake', label: 'Make', sortable: true},
        { key: 'TruckModel', label: 'Model', sortable: true },
        { key: 'TruckColor', label: 'Color', sortable: true },
        { key: 'TruckStatusDescription', label: 'Status', sortable: true },
        { key: 'SubStatus', sortable: true },
        { key: 'Reserved', sortable: true },
        { key: 'ReservedFor', sortable: true },
        { key: 'ReservedOn', sortable: true },
        { key: 'TruckNotes', sortable: true },
        { key: 'ChangeDate', label: 'Last Changed On', sortable: true },
        { key: 'RentalUse', label: 'Rental Use', sortable: true, class: 'rentalUse' }
      ],
      statusTable: [],
      statusDetails: [],
      statusDetailsOpen: false,
      stillLoadingStatusDetails: true,
      currentStatus: '',
      currentItem: 'ALL',
      currentAvailability: 'all',
      currentModel: 'all',
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      timeoutVar: null,
      permissions: [],
      rentalUseOverride: [],
    };
  },
  computed: mapGetters(['getModalData', 'getUserInfo']),
  mounted() {
    this.statusSelected(this.getModalData.modalCurrentStatus, this.getModalData.modalCurrentNewItem, this.getModalData.modalCurrentNewAvailability, this.getModalData.modalCurrentNewModel);
  },
  async created() {
    this.permissions = auth.permittedFeatures();
    await this.getVariablePermissions();
  },
  methods: {
    statusSelected(e, item, availability, truckModel) {
      this.currentStatus = e.TruckStatusDescription || e;
      this.currentItem = item;
      this.currentAvailability = availability;
      this.currentModel = truckModel;
      this.stillLoadingStatusDetails = true;
      this.statusDetailsOpen = true;
      this.perPage = 25;
      if (!item) {
        this.currentItem = 'ALL';
      }
      if (!availability) {
        this.currentAvailability = 'all';
      }
      if (!truckModel) {
        this.currentModel = 'all';
      }
      axios
        .get(`${constants.BASE_URL}/api/getStatusDetails/${this.currentStatus}/${this.currentItem}/${this.currentAvailability}/${this.currentModel}`, {
          headers: { Authorization: localStorage.getItem('token') },
        })
        .then(result => {
          this.statusDetails = result.data;
          this.totalRows = result.data.length;
          this.stillLoadingStatusDetails = false;
          this.$root.$emit('rentalTrades');
        }).catch(err => {
          console.log('error: ', err);
        })
    },
    showPerPage(amount) {
      const dropdownContainer = document.getElementById('per-page-dropdown');
      const dropdowns = dropdownContainer.getElementsByClassName('dropdown-item');
      for (let i = 0; i < dropdowns.length; i++) {
        if (dropdowns[i].classList.contains('active')) {
          dropdowns[i].classList.toggle('active');
        }
      }
      if (amount === 'All') {
        this.perPage = this.statusDetails.length;
        document.getElementById('perPageAll').classList.add('active');
      } else if (amount === 100) {
        this.perPage = amount;
        document.getElementById('perPage100').classList.add('active');
      } else if (amount === 50) {
        this.perPage = amount;
        document.getElementById('perPage50').classList.add('active');
      } else if (amount === 25) {
        this.perPage = amount;
        document.getElementById('perPage25').classList.add('active');
      }
    },
    exportDataToCSV() {
      let excelDownloadItems = [];
      for (let i = 0; i < this.statusDetails.length; i++) {
        if (this.currentStatus == 'NEW') {
          excelDownloadItems.push({
            UnitNumber: this.statusDetails[i].UnitNumber,
            Year: this.statusDetails[i].TruckYear,
            Make: this.statusDetails[i].TruckMake,
            Model: this.statusDetails[i].TruckModel,
            Color: this.statusDetails[i].TruckColor,
            Status: this.statusDetails[i].TruckStatusDescription,
            SubStatus: this.statusDetails[i].SubStatus,
            Reserved: this.statusDetails[i].Reserved,
            ReservedFor: this.statusDetails[i].ReservedFor,
            ReservedOn: this.statusDetails[i].ReservedOn,
            TruckNotes: this.statusDetails[i].TruckNotes,
            LastChangedOn: this.statusDetails[i].ChangeDate,
            RentalUse: this.statusDetails[i].RentalUse,
          })
        } else {
          excelDownloadItems.push({
            UnitNumber: this.statusDetails[i].UnitNumber,
            Year: this.statusDetails[i].TruckYear,
            Make: this.statusDetails[i].TruckMake,
            Model: this.statusDetails[i].TruckModel,
            Color: this.statusDetails[i].TruckColor,
            Status: this.statusDetails[i].TruckStatusDescription,
            SubStatus: this.statusDetails[i].SubStatus,
            Reserved: this.statusDetails[i].Reserved,
            ReservedFor: this.statusDetails[i].ReservedFor,
            ReservedOn: this.statusDetails[i].ReservedOn,
            TruckNotes: this.statusDetails[i].TruckNotes,
            LastChangedOn: this.statusDetails[i].ChangeDate,
            Late: this.statusDetails[i].OverrideLate != 1 && 
              (((this.currentStatus == 'Sold Not Delivered' || this.currentStatus == 'IN TRANSIT' || 
              this.currentStatus == 'IN TRANSIT (SELLER)' || this.currentStatus == 'ON LOT')
              && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
              ||
              ((this.currentStatus == 'NEW' || this.currentStatus == 'OFF RENT' || 
                this.currentStatus == 'RE-RENT' || this.currentStatus == 'BODY SHOP')
              && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
              ||
              ((this.currentStatus == 'Hold for Sale')
              && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
              ||
              ((this.currentStatus == 'At Auction' || this.currentStatus == 'In Maintenance' ||  
                this.currentStatus == 'FOR SALE' || this.currentStatus == 'RETAIL')
              && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
              ||
              ((this.currentStatus == 'Claims')
              && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (56*24*60*60*1000))) ? 
                'Yes' : this.statusDetails[i].OverrideReason != null ? 'No, ' + this.statusDetails[i].OverrideReason : 'No'
          })
        }
      }
      let d = new Date();
      let fileTime = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
      let fileName = `${this.currentStatus + 'Status' + fileTime}.csv`;
      const items = excelDownloadItems;
      const replacer = (key, value) => value === null ? '' : value // specify how to handle null values here
      const header = Object.keys(items[0]);
      let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '"').replace('\n', '\\n')).join(','))
      csv.unshift(header.join(','))
      this.downloadCSV(csv.join('\r\n'), fileName);
    },
    downloadCSV(csv, filename) {
      const csvFile = new Blob([csv], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    setRentalUse(unit, value) {
      clearTimeout(this.timeoutVar);
      axios
        .post(`${constants.BASE_URL}/api/setRentalUse`, {
          UnitNumber: unit,
          NewValue: value,
          ChangeTypeID: 6,
          ChangeFrom: 'Status Count',
        })
        .then(result => {
          for (let i = 0; i < this.statusDetails.length; i++) {
            if (this.statusDetails[i].UnitNumber == unit) {
              this.statusDetails[i].RentalUse = value;
              // setTimeout doesn't wait if you pass parameters unless you create anonymous function to then run it...
              let that = this;
              this.timeoutVar = setTimeout(function() { that.statusSelected(that.currentStatus, that.currentItem, that.currentAvailability, that.currentModel) }, 30000);
              break;
            }
          }
          // this.statusSelected(this.currentStatus);
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getVariablePermissions() {
      await axios.get(`${constants.BASE_URL}/api/variables/get-variables`, { params: { Group: 'Software' } })
        .then(result => {
          let vars = result.data;
          for (let i = 0; i < vars.length; i++) {
            if (vars[i].VariableName == 'Rental Use Override Permissions') {
              this.rentalUseOverride = vars[i].VariableValue;
            }
          }
        }).catch(err => {
          if (err && err.response && err.response.status === 403) {
            this.showLogOutModal = true;
          } else {
            console.log(err);
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';
#modal__statusDetails {
  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.perPageDropdown {
  margin-right: 100px;
  margin-bottom: 25px;
}
.red {
  color: rgb(180, 0, 0);
  font-weight: bold;
}

.export-excel__btn {
  cursor: pointer;
  padding: 0 10px;
  width: 168px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: white;
  height: 40px;
  display: flex;
  background-color: rgb(83, 172, 103);
  border: 3px solid rgb(120, 196, 138);
  border-radius: 40px;
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.75);
  justify-content: space-between;
  align-items: center;
}

.pad-top-6 {
  padding-top: 6px;
}

.margin-right-10 {
  margin-right: 10px;
}

// @media screen and (min-width: 1400px) {
//   .table-responsive {
//     padding-right: 5px;
//     overflow-x: auto;
//   }
// }

</style>
