<template>
  <div v-if="authorizedNavLinks && shouldDisplay" id="mainNav">
    <nav class="left-items">
      <div id="mainNav__logo">
        <router-link to="/home-apps" class="home-link"> 
        <!-- <router-link to="/home" class="home-link"> -->
          <img class="nav__barco--logo" src="@/assets/images/logoSmall.png" alt="barco logo" />
        </router-link>
      </div>
      <div class="nav__dropdown--options">
        <button
          @mouseenter="formsHover = true"
          @mouseleave="formsHover = false"
          @click="formsHover = true"
          class="mainNav__item"
        >
          Forms
          <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-down" />
          <div
            v-if="formsHover"
            id="mainNav__executive"
          >
            <router-link class="mainNav__item executive__item" to="/order-form" target="_blank"
              >Order Form</router-link
            >
            <router-link class="mainNav__item executive__item" to="/off-rent-form" target="_blank"
              >Off-Rent Form</router-link
            >
            <router-link class="mainNav__item executive__item" to="/trade-form" target="_blank"
              >Trade Form</router-link
            >
          </div>
        </button>
        <button
          v-if="executiveTabNamesArr.some(e => authorizedNavLinks.includes(e))"
          @mouseenter="executiveHover = true"
          @mouseleave="executiveHover = false"
          @click="executiveHover = true"
          class="mainNav__item"
        >
          Executive
          <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-down" />
          <div
            v-if="executiveHover"
            id="mainNav__executive"
          >
            <!-- //! If you add another link for just executive add it to executiveTabNamesArr as well -->
            <router-link
              v-if="ownerPermissions.includes(getUserInfo.UserID)"
              class="mainNav__item executive__item"
              to="/owners-stats"
              >Owners Stats</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('manheim-listings')"
              class="mainNav__item executive__item"
              to="/manheim-listings"
              >Manheim Listings</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('fleetcard')"
              class="mainNav__item executive__item"
              to="/fleetcard"
              >Fleet Card</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('truck-details')"
              class="mainNav__item executive__item"
              to="/truck-details"
              >Truck Details</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('profit-loss')"
              class="mainNav__item executive__item"
              to="/profit-loss"
              >Profit/Loss</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('employee-management') && employeePermissions.includes(getUserInfo.UserID)"
              class="mainNav__item executive__item"
              to="/employee-management"
              >Employees</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('activity-tracker')"
              class="mainNav__item executive__item"
              to="/activity-tracker"
              >Activity Tracker</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('page-analytics')"
              class="mainNav__item executive__item"
              to="/page-analytics"
              >Page Analytics</router-link
            >
          </div>
        </button>
        <button
          v-if="reportTabNamesArr.some(e => authorizedNavLinks.includes(e))"
          @mouseenter="reportsHover = true"
          @mouseleave="reportsHover = false"
          @click="reportsHover = true"
          class="mainNav__item reporting-button"
        >
          Reporting
        <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-down" />
          <div
            v-if="reportsHover"
            id="mainNav__executive"
          >
            <!-- <button
            v-if="authorizedNavLinks.includes('monthsinfleet') || authorizedNavLinks.includes('mileage-report')"
            @mouseenter="fleetHover = true"
            @mouseleave="fleetHover = false"
            @click="fleetHover = true"
            class="mainNav__item executive__item">
            Fleet
            <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-right" />
              <div v-if="fleetHover" class="fleet__item">
              </div>
            </button> -->

            <button
            v-if="authorizedNavLinks.includes('invoice-report') || authorizedNavLinks.includes('accounts-receivable')
              || authorizedNavLinks.includes('custom-report') || authorizedNavLinks.includes('billing') || authorizedNavLinks.includes('days-in-status-report')"
            @mouseenter="aRHover = true"
            @mouseleave="aRHover = false"
            @click="aRHover = true"
            class="mainNav__item executive__item">
            AR
            <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-right" />
              <div v-if="aRHover" class="fleet__item">
            <router-link
              v-if="authorizedNavLinks.includes('billing')"
              class="mainNav__item executive__item"
              to="/billing"
              >Billing</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('accounts-receivable')"
              class="mainNav__item executive__item"
              to="/credit-memos"
              >Credit Memos</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('invoice-report')"
              class="mainNav__item executive__item"
              to="/invoice-report"
              >Invoice Report</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('days-in-status-report')"
              class="mainNav__item executive__item"
              to="/days-in-status-report"
              >Days In Status</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('custom-report')"
              class="mainNav__item executive__item"
              to="/custom-report"
              >Custom Report</router-link
            >
              </div>
            </button>
            <button
            v-if="authorizedNavLinks.includes('monthsinfleet') || authorizedNavLinks.includes('mileage-report')
              || authorizedNavLinks.includes('statuscount') || authorizedNavLinks.includes('status-diff')
              || authorizedNavLinks.includes('lot-auction-count') || authorizedNavLinks.includes('trucks')
              || authorizedNavLinks.includes('bmr') || authorizedNavLinks.includes('fleetpie')
              || authorizedNavLinks.includes('industry') || authorizedNavLinks.includes('auction-report')
              || authorizedNavLinks.includes('new-trade-counts') || authorizedNavLinks.includes('new-only-counts')
              || authorizedNavLinks.includes('company-report') || authorizedNavLinks.includes('unit-hub')"
            @mouseenter="companyOverHover = true"
            @mouseleave="companyOverHover = false"
            @click="companyOverHover = true"
            class="mainNav__item executive__item">
            Operations
            <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-right" />
              <div v-if="companyOverHover" class="fleet__item">
                <!-- <router-link
                  v-if="authorizedNavLinks.includes('trucks')"
                  class="mainNav__item executive__item"
                  to="/trucks"
                  >On/Off Rent</router-link
                > -->
                <router-link
                  v-if="authorizedNavLinks.includes('statuscount')"
                  class="mainNav__item executive__item"
                  to="/statuscount"
                  >Status Count</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('status-diff')"
                  class="mainNav__item executive__item"
                  to="/status-diff"
                  >Status Diff</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('unit-hub')"
                  class="mainNav__item executive__item"
                  to="/unit-hub"
                  >Unit Hub</router-link
                >
                <!-- <router-link
                  v-if="authorizedNavLinks.includes('daily-counts')"
                  class="mainNav__item executive__item"
                  to="/daily-counts"
                  >Daily Counts</router-link
                > -->
                <router-link
                  v-if="authorizedNavLinks.includes('lot-auction-count') || authorizedNavLinks.includes('new-trade-counts') || authorizedNavLinks.includes('new-only-counts')"
                  class="mainNav__item executive__item"
                  to="/lot-auction-count"
                  >Lot & Auction Count</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('monthsinfleet')"
                  class="mainNav__item executive__item"
                  to="/monthsinfleet"
                  >Months In Fleet</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('days-on-rent-report')"
                  class="mainNav__item executive__item"
                  to="/days-on-rent"
                  >Days On Rent</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('trucks')"
                  class="mainNav__item executive__item"
                  to="/trucks"
                  >On/Off Rent</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('on-rent-optional-services')"
                  class="mainNav__item executive__item"
                  to="/on-rent-optional-services"
                  >On Rent Optional Services</router-link
                >
                <!-- Access for me, Rod, Brandon, Kevin & Camren -->
                <router-link
                  v-if="authorizedNavLinks.includes('bmr') 
                    "
                  class="mainNav__item executive__item"
                  to="/bmr"
                  >BMR</router-link
                >
                <!-- <router-link
                  v-if="authorizedNavLinks.includes('company-report')"
                  class="mainNav__item executive__item"
                  to="/company-report"
                  >Company</router-link
                > -->
                <router-link
                  v-if="authorizedNavLinks.includes('company-report')"
                  class="mainNav__item executive__item"
                  to="/client-info"
                >Customer Overview</router-link
                >
                <router-link
                v-if="authorizedNavLinks.includes('fleetpie')"
                class="mainNav__item executive__item"
                to="/fleetpie"
                >Fleet Pie</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('industry')"
                  class="mainNav__item executive__item"
                  to="/industry"
                  >Industry</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('mileage-report')"
                  class="mainNav__item executive__item"
                  to="/mileage-report"
                  >Mileage Report</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('auction-report')"
                  class="mainNav__item executive__item"
                  to="/auction-report"
                  >Auction Report</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('truck-count-by-state')"
                  class="mainNav__item executive__item"
                  to="/truck-count-by-state-new"
                  >Truck Count By State</router-link
                >
                <router-link
                  v-if="authorizedNavLinks.includes('stored-variables')"
                  class="mainNav__item executive__item"
                  to="/stored-variables"
                  >Edit Variables</router-link
                >
              </div>
            </button>
            <!-- //! If you add another link for just reporting add it to reportTabNamesArr as well -->
            <!--router-link
              v-if="authorizedNavLinks.includes('industry')"
              class="mainNav__item executive__item"
              to="/industry"
              >Industry</router-link
            >
            <router-link
              v-if="authorizedNavLinks.includes('company-report')"
              class="mainNav__item executive__item"
              to="/company-report"
              >Company Report</router-link
            -->
          </div>
        </button>
      </div>
    </nav>
    <nav>
      <button
          @mouseenter="rentalHover = true"
          @mouseleave="rentalHover = false"
          @click="rentalHover = true"
          class="mainNav__item"
          v-if="authorizedNavLinks.includes('onboarding') || authorizedNavLinks.includes('offboarding')"
        >
          Rental
          <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-down" />
          <div
            v-if="rentalHover"
            id="mainNav__executive"
          >
            <router-link class="mainNav__item executive__item" to="/onboarding" v-if="authorizedNavLinks.includes('onboarding')"
              >On Rent</router-link
            >
            <router-link class="mainNav__item executive__item" to="/offboarding" v-if="authorizedNavLinks.includes('offboarding')"
              >Off Rent</router-link
            >
          </div>
        </button>


      <!--router-link v-if="authorizedNavLinks.includes('trucks')" class="mainNav__item" to="/trucks"
        >Trucks</router-link
      -->
      <!--router-link
        v-if="authorizedNavLinks.includes('onboarding')"
        class="mainNav__item"
        to="/onboarding"
        >Onboarding</router-link
      >
      <router-link
        v-if="authorizedNavLinks.includes('offboarding')"
        class="mainNav__item"
        to="/offboarding"
        >Off Rent</router-link
      -->
      <router-link
        v-if="authorizedNavLinks.includes('transport')"
        class="mainNav__item"
        to="/transport"
        >Transport</router-link
      >
      <router-link
        v-if="authorizedNavLinks.includes('accounts-receivable')"
        class="mainNav__item"
        to="/accounts-receivable"
        >AR</router-link
      >
      <!-- <router-link
        v-if="authorizedNavLinks.includes('client-relations')"
        class="mainNav__item"
        to="/client-relations"
        >Client Relations</router-link
      > -->
      <router-link v-if="authorizedNavLinks.includes('recon')" class="mainNav__item" to="/recon"
        >Recon</router-link
      >
      <router-link v-if="authorizedNavLinks.includes('sales')" class="mainNav__item" to="/sales"
        >Sales</router-link
      >
      <router-link v-if="authorizedNavLinks.includes('titles')" class="mainNav__item" to="/titles"
        >Titles</router-link
      >
      <!-- <router-link class="mainNav__item" to="/overview">Overview</router-link> -->
      <!--router-link class="mainNav__item" to="/client-info">Overview</router-link-->
      <!--router-link v-if="authorizedNavLinks.includes('statuscount')" class="mainNav__item" to="/statuscount"
        >Status Count</router-link
      -->
    </nav>
    <!-- DROPDOWN MENU -->
    <div id="mainNav__dropdown">
      <button
        @mouseenter="userHover = true"
        @mouseleave="userHover = false"
        @click="userHover = true"
        class="mainNav__item user-addition"
      >
        {{ initials }}
        <font-awesome-icon class="mainNav__dropdown-icon" icon="caret-down" />
        <div
          v-if="userHover"
          id="mainNav__profile"
        >
          <router-link class="dropdown__content__item" to="/profile">Profile</router-link>
          <a class="dropdown__content__item" href="https://www.barcotrucks.com/employee-feedback" target="_blank">Emp. Feedback</a>
          <a class="dropdown__content__item" href="https://payrollexperts.myisolved.com/UserLogin.aspx" target="_blank">isolved Login</a>
          <a class="dropdown__content__item" href="https://barco-live.crm.dynamics.com/" target="_blank">CRM</a>
          <a @click="logout()" class="dropdown__content__item dropdown__content__item__sign-out"
            >Sign Out</a
          >
        </div>
      </button>
    </div>
    <!-- HAMBURGER MENU -->
    <div class="right-side__items">
      <div id="mainNav__hamburgerMenu">
        <font-awesome-icon
          @click="handleHamburgerMenuToggle"
          id="mainNav__hamburgerMenu__icon"
          icon="bars"
        />
        <div id="hamburgerMenu-container" class="menu-inactive">
          <router-link
            v-if="authorizedNavLinks.includes('onboarding')"
            class="hamburgerMenu__item"
            to="/onboarding"
            @click="handleHamburgerMenuToggle"
            >On Rent</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('offboarding')"
            class="hamburgerMenu__item"
            to="/offboarding"
            @click="handleHamburgerMenuToggle"
            >Off Rent</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('transport')"
            class="hamburgerMenu__item"
            to="/transport"
            @click="handleHamburgerMenuToggle"
            >Transport</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('accounts-receivable')"
            class="hamburgerMenu__item"
            to="/accounts-receivable"
            @click="handleHamburgerMenuToggle"
            >AR</router-link
          >
          <!-- <router-link
            v-if="authorizedNavLinks.includes('client-relations')"
            class="hamburgerMenu__item"
            to="/client-relations"
            @click.native="handleHamburgerMenuToggle"
            >Client Relations</router-link
          > -->
          <router-link
            v-if="authorizedNavLinks.includes('recon')"
            class="hamburgerMenu__item"
            to="/recon"
            @click="handleHamburgerMenuToggle"
            >Recon</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('sales')"
            class="hamburgerMenu__item"
            to="/sales"
            @click="handleHamburgerMenuToggle"
            >Sales</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('titles')"
            class="hamburgerMenu__item"
            to="/titles"
            @click="handleHamburgerMenuToggle"
            >Titles</router-link
          >
          <!-- // todo matt: delete v-if once overview finished -->
          <!--router-link class="hamburgerMenu__item" to="/overview">Overview</router-link-->
          <!--router-link class="hamburgerMenu__item" to="/statuscount">Status Count</router-link-->
          <!-- <router-link class="hamburgerMenu__item" to="/client-info">Overview</router-link> -->
          <!--router-link
            v-if="authorizedNavLinks.includes('trucks')"
            class="hamburgerMenu__item"
            to="/trucks"
            @click.native="handleHamburgerMenuToggle"
            >Trucks</router-link
          -->
          <router-link
            v-if="authorizedNavLinks.includes('monthsinfleet')"
            class="hamburgerMenu__item reporting-link"
            to="/monthsinfleet"
            >Months In Fleet</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('days-on-rent')"
            class="hamburgerMenu__item executive__item"
            to="/days-on-rent"
            >Days On Rent</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('trucks')"
            class="hamburgerMenu__item executive__item"
            to="/trucks"
            >On/Off Rent</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('on-rent-optional-services')"
            class="hamburgerMenu__item executive__item"
            to="/on-rent-optional-services"
            >On Rent Optional Services</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('bmr')
                    "
            class="hamburgerMenu__item reporting-link"
            to="/bmr"
            >BMR</router-link
          >
          <!--router-link
            v-if="authorizedNavLinks.includes('statuscount')"
            class="hamburgerMenu__item reporting-link"
            to="/statuscount"
            >Status Count</router-link
          -->
          <router-link
            v-if="authorizedNavLinks.includes('company-report')"
            class="hamburgerMenu__item executive__item"
            to="/client-info"
            >Customer Overview</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('industry')"
            class="hamburgerMenu__item reporting-link"
            to="/industry"
            >Industry</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('fleetpie')"
            class="hamburgerMenu__item reporting-link"
            to="/fleetpie"
            >Fleet Pie</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('company-report')"
            class="hamburgerMenu__item reporting-link"
            to="/company-report"
            >Company Report</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('auction-report')"
            class="hamburgerMenu__item reporting-link"
            to="/auction-report"
            >Auction Report</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('mileage-report')"
            class="hamburgerMenu__item reporting-link"
            to="/mileage-report"
            >Mileage Report</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('accounts-receivable')"
            class="hamburgerMenu__item reporting-link"
            to="/credit-memos"
            >Credit Memos</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('invoice-report')"
            class="hamburgerMenu__item reporting-link"
            to="/invoice-report"
            >Invoice Report</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('days-in-status-report')"
            class="hamburgerMenu__item reporting-link"
            to="/days-in-status-report"
            >Days In Status</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('custom-report')"
            class="hamburgerMenu__item reporting-link"
            to="/custom-report"
            >Custom Report</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('statuscount')"
            class="hamburgerMenu__item reporting-link"
            to="/statuscount"
            >Status Count</router-link
          >
          <!-- <router-link
            v-if="authorizedNavLinks.includes('daily-counts')"
            class="hamburgerMenu__item reporting-link"
            to="/daily-counts"
            >Daily Counts</router-link
          > -->
          <router-link
            v-if="authorizedNavLinks.includes('status-diff')"
            class="hamburgerMenu__item reporting-link"
            to="/status-diff"
            >Status Diff</router-link
          >
           <router-link
            v-if="authorizedNavLinks.includes('company-report')"
            class="hamburgerMenu__item reporting-link"
            to="/client-info"
            >Overview</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('activity-tracker')"
            class="hamburgerMenu__item reporting-link"
            to="/activity-tracker"
            >Activity Tracker</router-link
          >
          <router-link
            v-if="authorizedNavLinks.includes('page-analytics')"
            class="hamburgerMenu__item reporting-link"
            to="/page-analytics"
            >Page Analytics</router-link
          >
          <span class="small-screen__options">
            <router-link class="hamburgerMenu__item" to="/profile">Profile</router-link>
            <a class="hamburgerMenu__item" id="sign-out__item" @click="logout()">Sign Out</a>
            <router-link class="hamburgerMenu__item" to="https://www.barcotrucks.com/employee-feedback" target="_blank">Emp. Feedback</router-link>
            <router-link class="hamburgerMenu__item" to="https://payrollexperts.myisolved.com/UserLogin.aspx" target="_blank">isolved Login</router-link>
            <router-link class="hamburgerMenu__item" to="https://barco-live.crm.dynamics.com/" target="_blank">CRM</router-link>
          </span>
        </div>
        <div class="profile-icon" @click="handleProfileMenuToggle">
          <font-awesome-icon class="fa-lg nav__person-icon" icon="user-circle" />
          <font-awesome-icon class="fa-sm caret-icon" icon="caret-down" />
        </div>
        <div id="profileMenu-container" class="menu-inactive">
          <router-link class="profileMenu__item" to="/profile"
            @click="handleProfileMenuToggle">Profile</router-link>
          <router-link class="profileMenu__item" to="https://www.barcotrucks.com/employee-feedback" target="_blank"
            @click="handleProfileMenuToggle">Employee Feedback</router-link>
          <router-link class="profileMenu__item" to="https://payrollexperts.myisolved.com/UserLogin.aspx" target="_blank"
            @click="handleProfileMenuToggle">isolved Login</router-link>
          <router-link class="profileMenu__item" to="https://barco-live.crm.dynamics.com/" target="_blank"
            @click="handleProfileMenuToggle">CRM</router-link>
          <a class="profileMenu__item" id="sign-out__item" @click="logout()">Sign Out</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '../utils/auth/permissionRules';
import { mapGetters, mapActions } from 'vuex';
import constants from '@/assets/constants';
import axios from 'axios';
import variables from '../assets/scss/base/_variables.scss';

export default {
  name: 'Navbar',
  data() {
    return {
      executiveTabNamesArr: [
        'owners-stats',
        'fleetcard',
        'employee-management',
        'truck-details',
        'profit-loss',
        'manheim-listings',
        'activity-tracker'
      ],
      reportTabNamesArr: [
        'monthsinfleet',
        'bmr',
        'statuscount',
        'daily-counts',
        'industry',
        'fleetpie',
        'company-report',
        'auction-report',
        'mileage-report',
        'credit-memos',
        'invoice-report',
        'days-in-status-report',
        'custom-report',
        'truck-count-by-state',
        'lot-auction-count'
      ],
      dropdownActive: false,
      shouldDisplay: false,
      authorizedNavLinks: [],
      executiveHover: false,
      reportsHover: false,
      formsHover: false,
      rentalHover: false,
      userHover: false,
      fleetHover: false,
      companyOverHover: false,
      aRHover: false,
      initials: '',
      notFoundPath: '',
      navbarColorLoaded: false,
      employeePermissions: [],
      ownerPermissions: [],
    };
  },
  watch: {
    $route(to, from) {
    let domain = window.location.hostname;
    if (!this.navbarColorLoaded && domain !== 'www.barcodashboard.com' && this.$route.name != undefined && this.$route.name != 'notFound') {
      let currentPath = window.location.pathname;
      if (currentPath != '/employee-login' && currentPath != '/order-form' && currentPath != '/trade-form' && currentPath != '/off-rent-form') {
        this.navbarShouldDisplay();
        this.getVariablePermissions();
      }
    }
      if (this.authorizedNavLinks.length === 0) {
        this.getNavLinks();
        const tokenPayload = localStorage.getItem('token')
          ? localStorage.getItem('token').split(' ')[1]
          : '';
        if (this.$socket && localStorage.getItem('token')) {
          this.$socket.emit('assignGroups', tokenPayload);
        } else {
          console.error('Socket not available');
        }
      }
    },
  },
  computed: {
    ...mapGetters(['getUserInfo']),
  },
  async created() {
    if (localStorage.getItem('token')) {
      this.fetchUserInfo();
      setTimeout(() => {
        if (this.getUserInfo && this.getUserInfo.FirstName) {
          this.initials = this.getUserInfo.FirstName.substring(0, 1) + this.getUserInfo.LastName.substring(0, 1);
        } else {
          this.initials = 'User';
        }
      }, 1000);
    } 
    let domain = window.location.hostname;
    if (!this.navbarColorLoaded && domain !== 'www.barcodashboard.com' && this.$route.name != undefined && this.$route.name != 'notFound') {
      let currentPath = window.location.pathname;
      if (currentPath != '/employee-login' && currentPath != '/order-form' && currentPath != '/trade-form' && currentPath != '/off-rent-form') {
        await this.navbarShouldDisplay();
        await this.getVariablePermissions();
      }
    }
  },
  mounted() {
    let domain = window.location.hostname;
    if (!this.navbarColorLoaded && domain !== 'www.barcodashboard.com' && this.$route.name != undefined && this.$route.name != 'notFound') {
      let currentPath = window.location.pathname;
      if (currentPath != '/employee-login' && currentPath != '/order-form' && 
        currentPath != '/trade-form' && currentPath != '/off-rent-form') {
        axios
        .get(`${constants.BASE_URL}/getDataSource`, {
          headers: { Authorization: localStorage.getItem('token') },
        })
        .then(result => {
          if (result.data == 'local') {
            if (localStorage.getItem('impersonating') == 'true') {
              document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 50, 100)';
            } else {
              document.getElementById('mainNav').style.backgroundColor = 'rgb(3, 76, 25)';
            }
          } else if (result.data == 'test') {
            if (localStorage.getItem('impersonating') == 'true') {
              document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 50, 100)';
            } else {
              document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 69, 0)';
            }
          } else if (localStorage.getItem('impersonating') == 'true') {
            document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 50, 100)';
          }
          this.navbarColorLoaded = true;
        }).catch(err => {
          console.log('Token has expired, please login again');
        })
      }
    }
  },
  methods: {
    ...mapActions(['logout', 'fetchUserInfo']),

    handleDropdownTrans() {
      this.dropdownActive = !this.dropdownActive;
    },
    handleHamburgerMenuToggle() {
      document.getElementById('profileMenu-container').classList.remove('profileMenu-active');
      document.getElementById('profileMenu-container').classList.add('menu-inactive');
      document.getElementById('hamburgerMenu-container').classList.remove('menu-inactive');
      setTimeout(function() { document.getElementById('hamburgerMenu-container').classList.toggle('hamburgerMenu-active'); 
        if (!document.getElementById('hamburgerMenu-container').classList.contains('hamburgerMenu-active')) {
          setTimeout(function() { document.getElementById('hamburgerMenu-container').classList.add('menu-inactive') }, 225);
        }}, 25);
    },
    handleProfileMenuToggle() {
      document.getElementById('hamburgerMenu-container').classList.remove('hamburgerMenu-active');
      document.getElementById('hamburgerMenu-container').classList.add('menu-inactive');
      document.getElementById('profileMenu-container').classList.remove('menu-inactive');
      setTimeout(function() { document.getElementById('profileMenu-container').classList.toggle('profileMenu-active'); 
        if (!document.getElementById('profileMenu-container').classList.contains('profileMenu-active')) {
          setTimeout(function() { document.getElementById('profileMenu-container').classList.add('menu-inactive') }, 225);
        }}, 25);
    },
    navbarShouldDisplay() {
      const dismissNavRoutes = [
        'orderForm',
        'offRentForm',
        'login',
        'employee-login',
        'DamageForm',
        'Register',
        'passwordResetExternal',
        'documentation',
        'tradeForm',
        'notFound'
      ];

      if (!localStorage.getItem('token') || dismissNavRoutes.includes(this.$route.name)) {
        return (this.shouldDisplay = false);
      }
      axios
      .get(`${constants.BASE_URL}/getDataSource`, {
        headers: { Authorization: localStorage.getItem('token') },
      })
      .then(result => {
        if (result.data == 'local') {
          if (localStorage.getItem('impersonating') == 'true') {
            document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 50, 100)';
          } else {
            document.getElementById('mainNav').style.backgroundColor = 'rgb(3, 76, 25)';
          }
        } else if (result.data == 'test') {
          if (localStorage.getItem('impersonating') == 'true') {
            document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 50, 100)';
          } else {
            document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 69, 0)';
          }
        } else if (localStorage.getItem('impersonating') == 'true') {
          document.getElementById('mainNav').style.backgroundColor = 'rgb(255, 50, 100)';
        }
        this.navbarColorLoaded = true;
      }).catch(err => {
        console.log('Token has expired, please login again');
      })

      return (this.shouldDisplay = true);
    },
    async getVariablePermissions() {
      await axios.get(`${constants.BASE_URL}/api/variables/get-variables`, { params: { Group: 'Software' } })
        .then(result => {
          let vars = result.data;
          for (let i = 0; i < vars.length; i++) {
            if (vars[i].VariableName == 'Owners Stats Permissions') {
              this.ownerPermissions = vars[i].VariableValue;
            } else if (vars[i].VariableName == 'Employee Management Permissions') {
              this.employeePermissions = vars[i].VariableValue;
            }
          }
        }).catch(err => {
          if (err && err.response && err.response.status === 403) {
            this.showLogOutModal = true;
          } else {
            console.log(err);
          }
        })
    },
    async getNavLinks() {
      const navLinks = auth.permittedNavLink();
      this.authorizedNavLinks = navLinks;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
#mainNav {
  // position relative fixed it
  position: sticky;
  top: 0;
  z-index: 20;
  height: 50px;
  width: 100%;
  background: $navbarBgColor;
  @include F($j: space-between);
  padding: 0 5px;
  color: $navbarTextColor;
  font-family: $primaryFont;
  font-weight: 500;
  -webkit-box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.75);
}
// [data-url*="localhost"] #mainNav {
//    background: rgb(3, 76, 25);    
// }
#mainNav__logo {
  height: 55px;
  width: 85px;
  padding: 5px;
  margin-left: 5px;
  img {
    height: 100%;
  }
}
nav {
  height: 100%;
  @include F();
}
.mainNav__item {
  background: none;
  border: none;
  @include F();
  text-decoration: none;
  color: $navbarTextColor;
  height: 100%;
  padding: 0 15px;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  &:hover {
    color: var(--barco-hover-color) !important;
  }
}

.mainNav__dropdown-icon {
  margin: 0 5px;
}

button {
  position: relative;
}

#mainNav__executive {
  width: 200px;
  position: absolute;
  top: 99%;
  left: 0%;
  background: $navbarBgColor;
  padding: 10px 0;
  text-align: start;
}

#mainNav__profile {
  width: 200px;
  position: absolute;
  top: 99%;
  right: 0%;
  background: $navbarBgColor;
  padding: 10px 0;
  text-align: start;
}

.user-addition {
  margin-right: 0px;
}

.executive__item {
  padding: 10px 20px !important;
  display: block !important;
  width: 100%;
  text-align: left;
}

.hamburgerMenu__item.executive__item {
  padding: 10px 20px !important;
}

.router-link-exact-active {
  min-width: 100px;
  height: 41px;
  border-radius: 2px;
  color: $navbarTextColor !important;
  // background: #6C757D;
  //background: linear-gradient(65deg, rgba(27, 68, 149, 0.9), rgba(27, 149, 188, 0.9));
  background: linear-gradient(65deg, rgba(6, 77, 10, 0.9), rgba(210, 235, 212, 0.9));
}

.home-link {
  background: none;
}
// .router-link-exact-active:hover {
//   color: $navbarTextColor !important;
//   background: rgb(97, 105, 112);
// }

#mainNav__dropdown {
  position: relative;
  height: 100%;
  padding: 0 5px;
  &:hover {
    cursor: pointer;
  }
}
#dropdown__title {
  @include F();
  height: 100%;
  h3 {
    font-size: 1.2rem;
    margin: 0 5px;
  }
}
.dropdown-active {
  transform: translateX(0%) !important;
  #dropdown__title__icon {
    color: red !important;
  }
}
#dropdown__content {
  transform: translateX(110%);
  transition: 300ms ease-in-out transform;
  background: #111;
  position: absolute;
  top: 55px;
  right: 0;
  padding: 10px 0;
  border-radius: 0.4rem;
  overflow: hidden;
  width: 12rem;
  h3 {
    margin: 0 5px;
  }
  #dropdown__title__icon {
    transform: rotate(45deg);
  }
}
.dropdown__content__item {
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  color: $navbarTextColor;
  width: 100%;
  @include F($j: flex-start);
  margin: 0;
  padding: 10px 5px 10px 15px;
  &:hover {
    color: $barcoLightBlueFont;
  }
}

.dropdown__content__item__sign-out {
  &:hover {
    color: $barcoLightBlueFont;
  }
}

// Hamburger Custom Styles

#mainNav__hamburgerMenu__icon {
  margin: 0 10px 0 0;
  height: 33px;
  width: 33px;
  font-size: 2rem;
  padding: 4px 8px;
  &:hover {
    cursor: pointer;
    border-radius: 3px;
    background-color: #272727;
  }
}

#mainNav__hamburgerMenu {
  display: flex;
}

#hamburgerMenu-container {
  @include F(flex-start, flex-start, column);
  position: absolute;
  right: -133px;
  top: 53px;
  background: $navbarBgColor;
  border-radius: 0.2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 86vh;
  transition: right 200ms ease-in-out;
}

.hamburgerMenu-active {
  right: 3px !important;
}
.menu-inactive {
  display: none !important;
}

.small-screen__options {
  display: none;
}

.hamburgerMenu__item {
  font-size: 0.9rem;
  text-decoration: none;
  color: $navbarTextColor;
  width: 100%;
  @include F($j: flex-start);
  margin: 0;
  padding: 10px 20px;
  &:hover {
    background: $barcoLightBlueFont;
  }
}

a.hamburgerMenu__item.reporting-link {
  display: none;
}

#profileMenu-container {
  @include F(flex-start, flex-start, column);
  position: absolute;
  right: -123px;
  top: 53px;
  background: $navbarBgColor;
  border-radius: 0.2rem;
  overflow: hidden;
  transition: right 200ms ease-in-out;
}

.profileMenu-active {
  right: 3px !important;
}

.profileMenu__item {
  font-size: 0.9rem;
  text-decoration: none;
  color: $navbarTextColor;
  width: 100%;
  @include F($j: flex-start);
  margin: 0;
  padding: 15px 10px 15px 15px;
  &:hover {
    background: $barcoLightBlueFont;
  }
}

.left-items {
  display: flex;
  .nav__dropdown--options {
    display: flex;
    height: 100%;
  }
}

#sign-out__item {
  font-size: 0.9rem;
  text-decoration: none;
  color: $navbarTextColor;
  width: 100%;
  @include F($j: flex-start);
  margin: 0;
  padding: 15px 10px 15px 15px;
  &:hover {
    color: $navbarTextColor;
  }
}

.nav__person-icon {
  margin-right: 10px;
}

.right-side__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .profile-icon {
    height: 33px;
    width: 50px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: background-color 0.15s;
    cursor: pointer;
    .caret-icon {
      margin-left: -20px;
    }
  }

  .profile-icon:hover {
    background-color: #272727;
    border-radius: 3px;
  }
}

// Media Queries
//Make the menu shrink earlier

@media screen and (max-width: 1650px) {
  nav {
    display: none;
  }

  #mainNav__dropdown {
    display: none;
  }

  #mainNav__hamburgerMenu {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .nav__barco--logo {
    display: inline;
  }
}

@media screen and (min-width: 1651px) {
  .right-side__items {
    display: none;
  }
}

@media screen and (max-width: 912px) {
  .dropdown__content__item {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    padding: 10px 5px 10px 15px;
  }
  #dropdown__title {
    h3 {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
  .mainNav__item {
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0 10px;
  }
}

@media screen and (max-width: 650px) {
  nav {
    display: none;
  }

  #mainNav__dropdown {
    display: none;
  }

  #mainNav__hamburgerMenu {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .reporting-button {
    display: none;
  }
  a.hamburgerMenu__item.reporting-link {
    display: flex;
  }
}
@media screen and (max-width: 430px) {
  .profile-icon {
    display: none !important;
  }

  .small-screen__options {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #profileMenu-container {
    display: none;
  }
}

@media print {
  #mainNav {
    display: none;
  }
}

.fleet__item {
  background-color: #000;
  margin-left: 180px;
  margin-top: -46px;
  padding: 10px 0px;
  min-width: 220px;
  max-width: 300px;
  overflow-y: hidden;
  position: absolute;
}
@media screen and (max-height: 777px) {
  .fleet__item {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    max-height: 90vh;
  }
}
</style>
