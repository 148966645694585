<template>
  <div>
    <h5>Order Information:</h5>
    <div :class="orderErrors.CorporateNameTradeEmpty" class="edit-container">
      <b>Company Name:</b>
      <input
        required
        type="text"
        v-model="orderData.CorporateNameTrade"
        name="Company Name"
        :data-originalData="originalData.CorporateNameTrade"
        @input="handleChange"
      />
    </div>
    <div id="edit__compInfo-container">
      <div id="edit__compInfoOne-wrapper">
        <div class="edit-container">
          <b>PO Number:</b>
          <input
            type="text"
            v-model="orderData.PONumber"
            name="PO Number"
            maxlength="35"
            :data-originalData="originalData.PONumber"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.HalfTonCountTradeEmpty" class="edit-container">
          <b>Half Ton:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.HalfTonCountTrade"
            name="Half Tons"
            :data-originalData="originalData.HalfTonCountTrade"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.ThreeQuarterTonCountTradeEmpty" class="edit-container">
          <b>Three Quarter Ton:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.ThreeQuarterTonCountTrade"
            name="Three Quarter Tons"
            :data-originalData="originalData.ThreeQuarterTonCountTrade"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.OneTonFlatCountTradeEmpty" class="edit-container">
          <b>One Ton Flat:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.OneTonFlatCountTrade"
            name="One Ton Flatbeds"
            :data-originalData="originalData.OneTonFlatCountTrade"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.OneTonServiceCountTradeEmpty" class="edit-container">
          <b>One Ton Service:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.OneTonServiceCountTrade"
            name="One Ton Service Body's"
            :data-originalData="originalData.OneTonServiceCountTrade"
            @input="handleChange"
          />
        </div>
        <hr class="hr-total-new">
        <div class="edit-container total-new">
          <b>Total New Trucks:</b>
          <!-- {{ newRemainingTrades }} -->
          {{ newRemainingTrades }}
        </div>
      </div>
      <div id="edit__compInfoTwo-container">
        <div :class="orderErrors.CompanyCodeEmpty" class="edit-container">
          <b>Company Code:</b>
          <input
            required
            type="text"
            v-model="orderData.CompanyCode"
            name="Company Code"
            :data-originalData="originalData.CompanyCode"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.pickupDateEmpty" class="edit-container">
          <b>Requested Date:</b>
          <!-- <b-form-datepicker
            size="sm"
            v-model="orderData.pickupDate"
            @input="
              handleChange({
                target: {
                  name: 'Pickup Date',
                  value: orderData.pickupDate,
                  originalData: originalData.pickupDate,
                },
              })
            "
          ></b-form-datepicker> -->
          <datepicker
            size="sm"
            v-model="orderData.pickupDate"
            @date-update="dateClickedRequested"
            :enable-time-picker="false"
            :timezone="{ timezone: 'UTC', exactMatch: true }"
            :dark="getUserInfo.UserTheme == 'Dark' ? true : false"
            ignore-time-validation
            week-start="0"
            auto-apply
            vertical 
            arrow-navigation
            required
          ></datepicker>
        </div>
        <div class="edit-container">
          <b>Date Ready for Pickup:</b>
          <!-- <b-form-datepicker
            size="sm"
            v-model="orderData.dateReadyForPickup"
            @input="
              handleChange({
                target: {
                  name: 'Ready For Pickup',
                  value: orderData.dateReadyForPickup,
                  originalData: originalData.dateReadyForPickup,
                },
              })
            "
          ></b-form-datepicker> -->
          <datepicker
            v-model="orderData.dateReadyForPickup"
            @date-update="dateClickedReady"
            :enable-time-picker="false"
            :timezone="{ timezone: 'UTC', exactMatch: true }"
            :dark="getUserInfo.UserTheme == 'Dark' ? true : false"
            ignore-time-validation
            week-start="0"
            auto-apply
            vertical 
            arrow-navigation
            required
          ></datepicker>
        </div>
        <!-- // ! errors need to be added -->
        <div class="edit-container">
          <b>Damage Waiver</b>
          <b-dropdown
            id="damage-waiver"
            size="sm"
            :text="orderData.DamageWaiverID == 1 ? 'Bronze' : orderData.DamageWaiverID == 2 ? 'Silver' : 
              orderData.DamageWaiverID == 3 ? 'Gold' : orderData.DamageWaiverID == 4 ? 'Platinum' : 
              orderData.DamageWaiverID == 5 ? 'Diamond' : orderData.DamageWaiverID == 6 ? 'Full' : 'None Selected'"
            :variant="getUserInfo.UserTheme == 'Dark' ? 'dark' : 'light'"
          >
            <b-dropdown-item @click="setDamageWaiver(1)">Bronze</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(2)">Silver</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(3)">Gold</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(4)">Platinum</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(5)">Diamond</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(6)">Full</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="edit-container__key">
          <BFormCheckbox
            v-model="orderData.SecondKeyTrade"
            id="key_input"
            size="sm"
            name="Second Key"
            :data-originalData="originalData.SecondKeyTrade"
            @input.enter="handleChange"
          >
            <b>Second Key</b>
          </BFormCheckbox>
        </div>
        <div class="edit-container__key">
          <BFormCheckbox
            v-model="orderData.ConfirmReturnReady"
            id="return_input"
            size="sm"
            name="Return Ready Confirmation"
            :data-originalData="originalData.ConfirmReturnReady"
            @input.enter="handleChange"
          >
            <b>Return Ready Confirmation</b>
          </BFormCheckbox>
        </div>
      </div>
    </div>
    <div class="edit-container__vins">
      <b>Returning Vins Remaining:<span class="returning-vin-count">{{ vinOffset }} ({{ vinOffset - orderData.returningVins.length }}/{{ vinOffset }} completed)</span></b>
      <div class="edit__truckInfo-container">
        <div
          v-for="(truck, index) in orderData.returningVins"
          :key="truck.id"
          :class="{ 'edit__lock--styleOverride': truck.assignedStatus == 'assigned' }"
        >
          <div class="edit__truckInfo">
            <font-awesome-icon
              @click="truck.assignedStatus != 'assigned' && handleRemoveVin(index)"
              :class="truck.assignedStatus != 'assigned' ? 'edit__minus' : 'edit__lock'"
              :icon="truck.assignedStatus != 'assigned' ? 'minus-circle' : 'lock'"
            />
            <b>Unit Number:</b>
            <input
              :disabled="truck.assignedStatus == 'assigned'"
              required
              v-mask="'XXXXXXXX'"
              @input="e => ((vinsEdited = true), (e.target.value = e.target.value.toUpperCase()))"
              v-model="truck.vin"
            />
            <b>Odometer:</b>
            <input
              :disabled="truck.assignedStatus == 'assigned'"
              required
              v-mask="'#########'"
              v-model="truck.odometer"
            />
            <p v-if="truck.assignedStatus == 'assigned'" class="edit__lockText">Carrier Assigned</p>
            <p
              v-if="truck.assignedStatus != 'assigned' && validatedUnits[truck.vin]"
              class="edit__unitStats valid"
            >
              VALID UNIT NUMBER
            </p>
            <p
              v-if="truck.assignedStatus != 'assigned' && !vinsTested && !vinsEdited"
              class="edit__unitStats invalid"
            >
              NOT TESTED
            </p>
            <p
              v-if="truck.assignedStatus != 'assigned' && vinsTested && !validatedUnits[truck.vin] && !vinsEdited"
              class="edit__unitStats invalid"
            >
              INVALID UNIT NUMBER
            </p>
            <p
              v-if="truck.assignedStatus != 'assigned' && vinsEdited && !validatedUnits[truck.vin]"
              class="edit__unitStats invalid"
            >
              Test Required
            </p>
            
            <font-awesome-icon
              @click="truck.assignedStatus != 'assigned' && handleConvertCheck(index)"
              :class="truck.assignedStatus != 'assigned' ? 'convert_to_on_rent' : 'convert__lock'"
              :icon="truck.assignedStatus != 'assigned' ? 'random' : 'lock'"
            />
          </div>
          <div class="convert__truckInfo" v-if="confirmConvert && truck.vin == convertVin">
            <p class="convert__are_you_sure space-right">Are you sure you want to convert this traded unit into an On Rent?<br>
              This will remove the Off Rent half of the trade.
            <span class="convert__are_you_sure space-right italic-bold" v-if="newRemainingTrades != (vinOffset - 1)">
              <br>Please remove necessary truck from Total New Trucks count.
            </span> </p>

            <BButton 
              variant="danger" 
              class="convert__btn"
              :class="newRemainingTrades != (vinOffset - 1) ? 'disabled' : ''"
              :disabled="newRemainingTrades != (vinOffset - 1)"
              @click="handleConvert(index)"
              >Yes
            </BButton>
            <BButton 
              variant="secondary" 
              class="convert__btn"
              @click="confirmConvert = false"
              >No
            </BButton>
          </div>
        </div>
        <div class="edit__btns-container">
          <div @click="handleAddVin" id="edit__addVin">
            <font-awesome-icon class="edit__plus" icon="plus-circle" />
            <p>Add New Vin</p>
          </div>
          <div @click="handleVinTest" id="edit__addVin">
            <p id="edit__test">Validate Unit Numbers</p>
          </div>
        </div>
      </div>
    </div>
    <h5>{{ addDelivery ? 'Pickup Information' : transportPage ? 'Delivery Information' : 'Pickup & Delivery Info' }}:</h5>
    <div class="edit__pickInfo">
      <div id="edit__pickupInfoOne-container">
        <div :class="orderErrors.pickupNameEmpty" class="edit-container">
          <b>Name:</b>
          <input
            required
            type="text"
            v-model="orderData.pickupName"
            name="Pickup Name"
            :data-originalData="originalData.pickupName"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.pickupEmailEmpty" class="edit-container">
          <b>Email:</b>
          <input
            required
            type="text"
            v-model="orderData.pickupEmail"
            name="Pickup Email"
            :data-originalData="originalData.pickupEmail"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.pickupPhoneEmpty" class="edit-container">
          <b>Phone:</b>
          <input
            required
            v-mask="validatePickupPhone"
            type="text"
            v-model="orderData.pickupPhone"
            name="Pickup Phone"
            :data-originalData="originalData.pickupPhone"
            @input="handleChange"
          />
        </div>
        <div class="edit-container">
          <b>Alternate Phone:</b>
          <input
            v-mask="validatePickupAltPhone"
            type="text"
            v-model="orderData.pickupAltPhone"
            name="Pickup Alt Phone"
            :data-originalData="originalData.pickupAltPhone"
            @input="handleChange"
          />
        </div>
      </div>
      <div id="edit__pickupInfoTwo-container">
        <div :class="orderErrors.pickupStreetAddressEmpty" class="edit-container">
          <b>Street Address:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.pickupStreetAddress"
              name="Pickup Address"
              :data-originalData="originalData.pickupStreetAddress"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.pickupStreetAddress2Empty" class="edit-container">
          <b>Street Address 2:</b>
          <div id="edit__CSZ">
            <input
              type="text"
              v-model="orderData.pickupStreetAddress2"
              name="Pickup Address 2"
              :data-originalData="originalData.pickupStreetAddress2"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.pickupCityEmpty" class="edit-container">
          <b>City:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.pickupCity"
              name="Pickup City"
              :data-originalData="originalData.pickupCity"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.pickupStateEmpty" class="edit-container">
          <b>State:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.pickupState"
              name="Pickup State"
              :data-originalData="originalData.pickupState"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.pickupZipEmpty" class="edit-container">
          <b>Zip Code:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.pickupZip"
              v-mask="'#####'"
              name="Pickup Zip"
              :data-originalData="originalData.pickupZip"
              @input="handleChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="addDelivery">
      <h5>Delivery Information:</h5>
      <div class="edit__pickInfo">
        <div id="edit__deliveryInfoOne-container">
          <div :class="orderErrors.deliveryNameEmpty" class="edit-container">
            <b>Name:</b>
            <input
              type="text"
              v-model="orderData.deliveryName"
              name="Delivery Name"
              :data-originalData="originalData.deliveryName"
              @input="handleChange"
            />
          </div>
          <div :class="orderErrors.deliveryEmailEmpty" class="edit-container">
            <b>Email:</b>
            <input
              type="text"
              v-model="orderData.deliveryEmail"
              name="Delivery Email"
              :data-originalData="originalData.deliveryEmail"
              @input="handleChange"
            />
          </div>
          <div :class="orderErrors.deliveryPhoneEmpty" class="edit-container">
            <b>Phone:</b>
            <input
              v-mask="validateDelPhone"
              type="text"
              v-model="orderData.deliveryPhone"
              name="Delivery Phone"
              :data-originalData="originalData.deliveryPhone"
              @input="handleChange"
            />
          </div>
          <div class="edit-container">
            <b>Alternate Phone:</b>
            <input
              v-mask="validateDelAltPhone"
              type="text"
              v-model="orderData.deliveryAltPhone"
              name="Delivery Alt Phone"
              :data-originalData="originalData.deliveryAltPhone"
              @input="handleChange"
            />
          </div>
        </div>
        <div id="edit__deliveryInfoTwo-container">
          <div :class="orderErrors.deliveryStreetAddressEmpty" class="edit-container">
            <b>Street Address:</b>
            <div id="edit__CSZ">
              <input
                type="text"
                v-model="orderData.deliveryStreetAddress"
                name="Delivery Address"
                :data-originalData="originalData.deliveryStreetAddress"
                @input="handleChange"
              />
            </div>
          </div>
          <div :class="orderErrors.deliveryStreetAddress2Empty" class="edit-container">
            <b>Street Address 2:</b>
            <div id="edit__CSZ">
              <input
                type="text"
                v-model="orderData.deliveryStreetAddress2"
                name="Delivery Address 2"
                :data-originalData="originalData.deliveryStreetAddress2"
                @input="handleChange"
              />
            </div>
          </div>
          <div :class="orderErrors.deliveryCityEmpty" class="edit-container">
            <b>City:</b>
            <div id="edit__CSZ">
              <input
                type="text"
                v-model="orderData.deliveryCity"
                name="Delivery City"
                :data-originalData="originalData.deliveryCity"
                @input="handleChange"
              />
            </div>
          </div>
          <div :class="orderErrors.deliveryStateEmpty" class="edit-container">
            <b>State:</b>
            <div id="edit__CSZ">
              <input
                type="text"
                v-model="orderData.deliveryState"
                name="Delivery State"
                :data-originalData="originalData.deliveryState"
                @input="handleChange"
              />
            </div>
          </div>
          <div :class="orderErrors.deliveryZipEmpty" class="edit-container">
            <b>Zip Code:</b>
            <div id="edit__CSZ">
              <input
                type="text"
                v-model="orderData.deliveryZip"
                v-mask="'#####'"
                name="Delivery Zip"
                :data-originalData="originalData.deliveryZip"
                @input="handleChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="add-delivery">
        <BButton @click="
                  () => {
                    this.addDelivery = false;
                  }">Remove seperate delivery address</BButton>
      </div>
    </div>
    <div v-else-if="transportPage == false" class="add-delivery">
      <BButton @click="
                () => {
                  this.addDelivery = true;
                }">Add seperate delivery address</BButton>
    </div>
    <h5>Use Information:</h5>
    <div class="edit__pickInfo">
      <div id="edit__pickupInfoOne-container">
        <div :class="orderErrors.UseStreetAddressEmpty" class="edit-container">
          <b>Street Address:</b>
          <input
            required
            type="text"
            v-model="orderData.useStreetAddress"
            name="Use Address"
            :data-originalData="originalData.UseStreetAddress"
            @input="handleChange"
          />
        </div>
        <div class="edit-container">
          <b>Street Address 2:</b>
          <input
            required
            type="text"
            v-model="orderData.useStreetAddress2"
            name="Use Address 2"
            :data-originalData="originalData.UseStreetAddress2"
            @input="handleChange"
          />
        </div>
      </div>
      <div id="edit__pickupInfoTwo-container">
        <div :class="orderErrors.UseCityEmpty" class="edit-container">
          <b>City:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.useCity"
              name="Use City"
              :data-originalData="originalData.UseCity"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.UseStateEmpty" class="edit-container">
          <b>State:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.useState"
              name="Use State"
              :data-originalData="originalData.UseState"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.UseZipEmpty" class="edit-container">
          <b>Zip Code:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.useZip"
              v-mask="'#####'"
              name="Use Zip"
              :data-originalData="originalData.UseZip"
              @input="handleChange"
            />
          </div>
        </div>
      </div>
    </div>
    <h5>Rental Agreement Information:</h5>
    <div class="edit__pickInfo">
      <div id="edit__rentalInfoOne-container">
        <div :class="orderErrors.RANameEmpty" class="edit-container">
          <b>Name:</b>
          <input
            required
            type="text"
            v-model="orderData.raName"
            name="RA Name"
            :data-originalData="originalData.raName"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.RAPhoneEmpty" class="edit-container">
          <b>Phone:</b>
          <input
            required
            v-mask="validateRAPhone"
            type="text"
            v-model="orderData.raPhone"
            name="RA Phone"
            :data-originalData="originalData.raPhone"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.RAEmailEmpty" class="edit-container">
          <b>Email:</b>
          <input
            required
            type="text"
            v-model="orderData.raEmail"
            name="RA Email"
            :data-originalData="originalData.raEmail"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.raFax" class="edit-container">
          <b>Fax:</b>
          <input type="text" v-model="orderData.raFax" />
        </div>
      </div>
    </div>
    <p id="error">{{ error }}</p>
    <BButton v-if="!spinners.saveChanges" variant="success" @click="handleSaveChanges">Save Changes</BButton>
    <BSpinner v-if="spinners.saveChanges" id="b-spinner--add-btn__spinner"></BSpinner>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import constants from '@/assets/constants';

export default {
  name: 'editOffboarding',
  // computed: mapGetters(['getCurrentNoteOrder']),
  data() {
    return {
      error: '',
      spinners: {
        saveChanges: false,
      },
      vinsEdited: false,
      validatedUnits: {},
      vinsTested: false,
      validChecks: {},
      originalData: {},
      orderData: {
        changedData: {},
        CorporateNameTrade: '',
        CompanyCode: '',
        DamageWaiverID: 0,
        SecondKeyTrade: null,
        ConfirmReturnReady: null,
        pickupDate: null,
        dateReadyForPickup: null,
        returningVins: [],
        deletedVins: [],
        pickupName: '',
        pickupEmail: '',
        pickupPhone: '',
        pickupAltPhone: '',
        pickupStreetAddress: '',
        pickupStreetAddress2: '',
        pickupCity: '',
        pickupState: '',
        pickupZip: '',
        deliveryName: '',
        deliveryEmail: '',
        deliveryPhone: '',
        deliveryAltPhone: '',
        deliveryStreetAddress: '',
        deliveryStreetAddress2: '',
        deliveryCity: '',
        deliveryState: '',
        deliveryZip: '',
        useStreetAddress: '',
        useStreetAddress2: '',
        useCity: '',
        useState: '',
        useZip: '',
        PONumber: '',
        ReturnTrucksRemaining: '',
        NewTrucksRemaining: '',
        raName: '',
        raPhone: '',
        raEmail: '',
        raFax: '',
        submitteeName: '',
        specialInstructions: '',
        submitDate: '',
        insuranceType: 0,
        orderPage: '',
      },
      orderErrors: {
        CorporateNameTradeEmpty: '',
        CompanyCodeEmpty: '',
        HalfTonCountTradeEmpty: '',
        ThreeQuarterTonCountTradeEmpty: '',
        OneTonFlatCountTradeEmpty: '',
        OneTonServiceCountTradeEmpty: '',
        SecondKeyTradeEmpty: '',
        pickupDateEmpty: '',
        returningVinsEmpty: '',
        deletedVinsEmpty: '',
        pickupNameEmpty: '',
        pickupEmailEmpty: '',
        pickupPhoneEmpty: '',
        pickupAltPhoneEmpty: '',
        pickupStreetAddressEmpty: '',
        pickupStreetAddress2Empty: '',
        pickupCityEmpty: '',
        pickupStateEmpty: '',
        pickupZipEmpty: '',
        deliveryNameEmpty: '',
        deliveryEmailEmpty: '',
        deliveryPhoneEmpty: '',
        deliveryAltPhoneEmpty: '',
        deliveryStreetAddressEmpty: '',
        deliveryStreetAddress2Empty: '',
        deliveryCityEmpty: '',
        deliveryStateEmpty: '',
        deliveryZipEmpty: '',
        useStreetAddressEmpty: '',
        useCityEmpty: '',
        useStateEmpty: '',
        useZipEmpty: '',
        raNameEmpty: '',
        raPhoneEmpty: '',
        raEmailEmpty: '',
      },
      addDelivery: false,
      transportPage: false,
      vinOffset: 0,
      newRemainingTrades: 0,
      convertVin: '',
      confirmConvert: false,
      convertData: {},
      ogDamageWaiver: '',
      ogSecondKey: 'No',
      ogConfirmedReady: 'No'
    };
  },
  computed: {
    ...mapGetters(['getCurrentNoteOrder', 'getUserInfo']),
    validatePickupPhone() {
      if (this.orderData.pickupPhone == '') {
        return '';
      } else if (this.orderData.pickupPhone && (this.orderData.pickupPhone.substring(0, 1) == '+' || this.orderData.pickupPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validatePickupAltPhone() {
      if (this.orderData.pickupAltPhone == '') {
        return '';
      } else if (this.orderData.pickupAltPhone && (this.orderData.pickupAltPhone.substring(0, 1) == '+' || this.orderData.pickupAltPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validateDelPhone() {
      if (this.orderData.deliveryPhone && (this.orderData.deliveryPhone.substring(0, 1) == '+' || this.orderData.deliveryPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validateDelAltPhone() {
      if (this.orderData.deliveryAltPhone && (this.orderData.deliveryAltPhone.substring(0, 1) == '+' || this.orderData.deliveryAltPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validateRAPhone() {
      if (this.orderData.raPhone == '') {
        return '';
      } else if (this.orderData.raPhone && (this.orderData.raPhone.substring(0, 1) == '+' || this.orderData.raPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['closeModal']),
    init() {
      if (this.getCurrentNoteOrder.Name) {
        this.transportPage = true;
      }
      if (this.$route.name === 'transport') {
        this.orderData.orderPage = 'transport';
      } else if (this.$route.name === 'onboarding') {
        this.orderData.orderPage = 'onboarding';
      }
      this.orderData.PONumber =
        this.getCurrentNoteOrder.PONumber || this.getCurrentNoteOrder.PONumberTrade;
      this.orderData.TradeFormID = this.getCurrentNoteOrder.TradeFormID;
      this.orderData.FormHistoryID = this.getCurrentNoteOrder.FormHistoryID;
      this.orderData.CorporateNameTrade =
        this.getCurrentNoteOrder.CorporateNameTrade || this.getCurrentNoteOrder.CorporateName;
      this.orderData.CompanyCode =
        this.getCurrentNoteOrder.CompanyNumberTrade || this.getCurrentNoteOrder.CompanyNumber ||
        this.getCurrentNoteOrder.CompanyCode ||
        '00000';
      this.orderData.CompanyCode = this.orderData.CompanyCode.toString().padStart(5, '0');

      this.orderData.pickupDate = new Date(
        this.getCurrentNoteOrder.ReturnDate || this.getCurrentNoteOrder.DateNeeded
      );
      this.orderData.dateReadyForPickup = this.getCurrentNoteOrder.DatePickupReady ? new Date(this.getCurrentNoteOrder.DatePickupReady) : null;
      this.orderData.pickupDate = this.orderData.pickupDate.toISOString().split('T')[0];
      this.orderData.dateReadyForPickup = this.getCurrentNoteOrder.DatePickupReady ? this.orderData.dateReadyForPickup.toISOString().split('T')[0] : null;
      this.orderData.SecondKeyTrade =
        !!this.getCurrentNoteOrder.SecondKeyTrade || !!this.getCurrentNoteOrder.SecondKeyCount;
      this.ogSecondKey = this.orderData.SecondKeyTrade;
      this.orderData.ConfirmReturnReady = 
        !!this.getCurrentNoteOrder.ConfirmReturnReady || !!this.getCurrentNoteOrder.ConfirmReturnReady;
      this.ogConfirmedReady = this.orderData.ConfirmReturnReady;
      this.orderData.HalfTonCountTrade =
        this.getCurrentNoteOrder.HalfTonCountTrade || this.getCurrentNoteOrder.HalfTonCount;
      this.orderData.ThreeQuarterTonCountTrade =
        this.getCurrentNoteOrder.ThreeQuarterTonCountTrade ||
        this.getCurrentNoteOrder.ThreeQuarterTonCount;
      this.orderData.OneTonFlatCountTrade =
        this.getCurrentNoteOrder.OneTonFlatCountTrade || this.getCurrentNoteOrder.OneTonFlatCount;
      this.orderData.OneTonServiceCountTrade =
        this.getCurrentNoteOrder.OneTonServiceCountTrade ||
        this.getCurrentNoteOrder.OneTonServiceCount;
      this.orderData.returningVins = this.getCurrentNoteOrder.TruckTrade
        ? this.getCurrentNoteOrder.TruckTrade.split(',').map(vinInfo => {
            const response = vinInfo.split(' - ');
            return {
              originalInfo: {
                vin: response[0],
                odometer: response[1],
                assignedStatus: response[2],
              },
              vin: response[0],
              odometer: response[1],
              assignedStatus: response[2],
            };
          })
        : this.getCurrentNoteOrder.Truck.split(' # ,').map(vinInfo => {
            const response = vinInfo.split(' - ');

            return {
              originalInfo: {
                vin: response[0],
                odometer: response[5],
                assignedStatus: response[2],
              },
              vin: response[0],
              odometer: response[5],
              assignedStatus: response[2],
            };
          });

      // Name if coming from Transport, PickupName from Onboarding page
      this.orderData.pickupName =
        this.getCurrentNoteOrder.PickupName || this.getCurrentNoteOrder.Name;
      this.orderData.pickupEmail = this.getCurrentNoteOrder.PickupEmail || this.getCurrentNoteOrder.Email;
      this.orderData.pickupPhone =
        this.getCurrentNoteOrder.PickupPhone || this.getCurrentNoteOrder.Phone;
      this.orderData.pickupAltPhone = this.getCurrentNoteOrder.PickupAltPhone || this.getCurrentNoteOrder.AltPhone;
      this.orderData.pickupStreetAddress =
        this.getCurrentNoteOrder.PickupStreetAddress || this.getCurrentNoteOrder.StreetAddress;
      this.orderData.pickupStreetAddress2 =
        this.getCurrentNoteOrder.PickupStreetAddress2 || this.getCurrentNoteOrder.Street2;
      this.orderData.pickupCity =
        this.getCurrentNoteOrder.PickupCity || this.getCurrentNoteOrder.City;
      this.orderData.pickupState =
        this.getCurrentNoteOrder.PickupState || this.getCurrentNoteOrder.State;
      this.orderData.pickupZip = this.getCurrentNoteOrder.PickupZip || this.getCurrentNoteOrder.Zip;

      this.orderData.deliveryName = this.getCurrentNoteOrder.DeliveryName;
      this.orderData.deliveryEmail = this.getCurrentNoteOrder.DeliveryEmail;
      this.orderData.deliveryPhone = this.getCurrentNoteOrder.DeliveryPhone;
      this.orderData.deliveryAltPhone = this.getCurrentNoteOrder.DeliveryAltPhone;
      this.orderData.deliveryStreetAddress = this.getCurrentNoteOrder.DeliveryStreetAddress;
      this.orderData.deliveryStreetAddress2 = this.getCurrentNoteOrder.DeliveryStreetAddress2;
      this.orderData.deliveryCity = this.getCurrentNoteOrder.DeliveryCity;
      this.orderData.deliveryState = this.getCurrentNoteOrder.DeliveryState;
      this.orderData.deliveryZip = this.getCurrentNoteOrder.DeliveryZip;
      
      this.orderData.useStreetAddress = this.getCurrentNoteOrder.UseStreetAddress;
      this.orderData.useStreetAddress2 = this.getCurrentNoteOrder.UseStreetAddress2;
      this.orderData.useCity = this.getCurrentNoteOrder.UseCity;
      this.orderData.useState = this.getCurrentNoteOrder.UseState;
      this.orderData.useZip = this.getCurrentNoteOrder.UseZip;

      this.orderData.raName = this.getCurrentNoteOrder.RANameTrade || this.getCurrentNoteOrder.RAName;
      this.orderData.raPhone = this.getCurrentNoteOrder.RAPhoneTrade || this.getCurrentNoteOrder.RAPhone;
      this.orderData.raEmail = this.getCurrentNoteOrder.RAEmailTrade || this.getCurrentNoteOrder.RAEmail;
      this.orderData.raFax = this.getCurrentNoteOrder.RAFaxTrade || this.getCurrentNoteOrder.RAFax;
      
      this.orderData.submitteeName = this.getCurrentNoteOrder.SubmitteeName;
      this.orderData.specialInstructions = this.getCurrentNoteOrder.SpecialInstructions;
      this.orderData.submitDate = this.getCurrentNoteOrder.SubmitDateTrade ? this.getCurrentNoteOrder.SubmitDateTrade : this.getCurrentNoteOrder.SubmitDate;
      this.orderData.insuranceType = this.getCurrentNoteOrder.InsuranceType;
      
      // Damage Waiver will default to 1 if order doesn't have one selected previously
      this.orderData.DamageWaiverID = this.getCurrentNoteOrder.DamageWaiverID || 1;
      this.ogDamageWaiver = this.orderData.DamageWaiverID == 1 ? 'Bronze' : this.orderData.DamageWaiverID == 2 ? 'Silver' : 
        this.orderData.DamageWaiverID == 3 ? 'Gold' : this.orderData.DamageWaiverID == 4 ? 'Platinum' : 
        this.orderData.DamageWaiverID == 5 ? 'Diamond' : this.orderData.DamageWaiverID == 6 ? 'Full' : 'None Selected';

      if (this.getCurrentNoteOrder.DeliveryName) {
        this.addDelivery = true;
      }

      // - ============ Handle falsy values ============
      this.orderData.SecondKeyTrade = !this.orderData.SecondKeyTrade
        ? false
        : this.orderData.SecondKeyTrade;
      this.orderData.ConfirmReturnReady = !this.orderData.ConfirmReturnReady
        ? false
        : this.orderData.ConfirmReturnReady
      this.orderData.HalfTonCountTrade = !this.orderData.HalfTonCountTrade
        ? 0
        : this.orderData.HalfTonCountTrade;
      this.orderData.ThreeQuarterTonCountTrade = !this.orderData.ThreeQuarterTonCountTrade
        ? 0
        : this.orderData.ThreeQuarterTonCountTrade;
      this.orderData.OneTonFlatCountTrade = !this.orderData.OneTonFlatCountTrade
        ? 0
        : this.orderData.OneTonFlatCountTrade;
      this.orderData.OneTonServiceCountTrade = !this.orderData.OneTonServiceCountTrade
        ? 0
        : this.orderData.OneTonServiceCountTrade;

      this.orderData.ReturnTrucksRemaining = this.getCurrentNoteOrder.ReturnTrucksRemaining;
      this.orderData.NewTrucksRemaining = this.getCurrentNoteOrder.NewTrucksRemaining != null ? 
        this.getCurrentNoteOrder.NewTrucksRemaining : this.getCurrentNoteOrder.TrucksRemaining;
      this.vinOffset = parseInt(this.orderData.HalfTonCountTrade, 10) + parseInt(this.orderData.ThreeQuarterTonCountTrade, 10) + 
                      parseInt(this.orderData.OneTonServiceCountTrade, 10) + parseInt(this.orderData.OneTonFlatCountTrade, 10);
      this.originalData = { ...this.orderData };
      this.convertData = { ...this.orderData };

      this.convertData.NumTrucksReturning = this.getCurrentNoteOrder.NumTrucks;

      if(this.$route.name === 'onboarding') {
        this.convertData.deliveryName = this.getCurrentNoteOrder.DeliveryName;
        this.convertData.deliveryEmail = this.getCurrentNoteOrder.DeliveryEmail;
        this.convertData.deliveryPhone = this.getCurrentNoteOrder.DeliveryPhone;
        this.convertData.deliveryAltPhone = this.getCurrentNoteOrder.DeliveryAltPhone;
        this.convertData.deliveryStreetAddress = this.getCurrentNoteOrder.DeliveryStreetAddress;
        this.convertData.deliveryStreetAddress2 = this.getCurrentNoteOrder.DeliveryStreetAddress2;
        this.convertData.deliveryCity = this.getCurrentNoteOrder.DeliveryCity;
        this.convertData.deliveryState = this.getCurrentNoteOrder.DeliveryState;
        this.convertData.deliveryZip = this.getCurrentNoteOrder.DeliveryZip;
      } else {
        this.convertData.deliveryName = this.orderData.PickupName;
        this.convertData.deliveryEmail = this.orderData.PickupEmail;
        this.convertData.deliveryPhone = this.orderData.PickupPhone;
        this.convertData.deliveryAltPhone = this.orderData.PickupAltPhone;
        this.convertData.deliveryStreetAddress = this.orderData.PickupStreetAddress;
        this.convertData.deliveryStreetAddress2 = this.orderData.PickupStreetAddress2;
        this.convertData.deliveryCity = this.orderData.PickupCity;
        this.convertData.deliveryState = this.orderData.PickupState;
        this.convertData.deliveryZip = this.orderData.PickupZip;
      }

      this.newRemainingTrades = 
          +this.orderData.HalfTonCountTrade +
          +this.orderData.ThreeQuarterTonCountTrade +
          +this.orderData.OneTonFlatCountTrade +
          +this.orderData.OneTonServiceCountTrade;
    },
    async handleSaveChanges() {
      this.spinners.saveChanges = true;
      // * Validates fields
      if (!(this.handleValidate())) {
        this.spinners.saveChanges = false;
        return;
      }

      // * Validates Unit Numbers
      await this.handleVinTest();

      const testEnteredVins = this.orderData.returningVins.map(
        vinInfo => this.validatedUnits[vinInfo.vin]
      );

      if (testEnteredVins.includes(false)) {
        // this.orderErrors[Object.keys(validationData)[index] + "Empty"] =
        //   "error-box";
        this.error = 'All edited vins must be tested and valid';
        this.spinners.saveChanges = false;
        return;
      }

      // Update Remaining trucks if necessary
      if (this.orderData.HalfTonCountTrade != this.originalData.HalfTonCountTrade ||
          this.orderData.ThreeQuarterTonCountTrade != this.originalData.ThreeQuarterTonCountTrade ||
          this.orderData.OneTonServiceCountTrade != this.originalData.OneTonServiceCountTrade ||
          this.orderData.OneTonFlatCountTrade != this.originalData.OneTonFlatCountTrade) {
        let tempTotalOrig = parseInt(this.originalData.HalfTonCountTrade) + parseInt(this.originalData.ThreeQuarterTonCountTrade) + 
                            parseInt(this.originalData.OneTonServiceCountTrade) + parseInt(this.originalData.OneTonFlatCountTrade);
        let tempTotalNew = parseInt(this.orderData.HalfTonCountTrade) + parseInt(this.orderData.ThreeQuarterTonCountTrade) +
                            parseInt(this.orderData.OneTonServiceCountTrade) + parseInt(this.orderData.OneTonFlatCountTrade);
        let tempTotalDiff = tempTotalOrig - tempTotalNew;
        this.orderData.NewTrucksRemaining = parseInt(this.orderData.NewTrucksRemaining) - tempTotalDiff;
      }

      for (let i = 0; i < this.orderData.returningVins.length; i++) {
        let ogInfo = this.orderData.returningVins[i].originalInfo;
        if (ogInfo && ogInfo.odometer.includes(' # ')) {
          ogInfo.odometer = ogInfo.odometer.replace(' # ', '');
        }
      }

      if (this.addDelivery === false) {
        this.orderData.deliveryName = '';
        this.orderData.deliveryPhone = '';
        this.orderData.deliveryEmail = '';
        this.orderData.deliveryAltPhone = '';
        this.orderData.deliveryStreetAddress = '';
        this.orderData.deliveryStreetAddress2 = '';
        this.orderData.deliveryCity = '';
        this.orderData.deliveryState = '';
        this.orderData.deliveryZip = '';
      }
      await axios
        .put(`${constants.BASE_URL}/api/trade/on/edit`, this.orderData)
        .then(res => {
          if (this.$route.name === 'transport') {
            this.$socket.emit('transportCardsRefresh');
          } else {
            this.$socket.emit('onBoardingCardsRefresh');
          }
          this.spinners.saveChanges = false;
          // this.closeModal();
          this.$emit('closeModal');
        })
        .catch(err => {
          console.log(err);
          setTimeout(() => (this.error = ''), 6000);
          this.error = 'All fields must be filled...';
          this.spinners.saveChanges = false;
          this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = 'error-box';
        });
    },
    handleAddVin() {
      this.orderData.returningVins.push({
        vin: 'UPDATE',
        odometer: '000000',
      });
      this.orderData.ReturnTrucksRemaining += 1;
      this.vinOffset += 1;
    },
    handleRemoveVin(index) {
      if (this.orderData.returningVins[index].originalInfo) {
        this.orderData.deletedVins.push(this.orderData.returningVins[index]);
      }
      this.orderData.returningVins.splice(index, 1);
      this.orderData.ReturnTrucksRemaining -= 1;
      this.vinOffset -= 1;
    },
    handleConvertCheck(index) {
      this.convertVin = this.orderData.returningVins[index].originalInfo.vin;
      this.confirmConvert = true;
      this.convertData.convertVin = this.convertVin;
    },
    async handleConvert(index) {
      if(this.newRemainingTrades == (this.vinOffset - 1)) {
        this.convertData.HalfTonCount = this.convertData.HalfTonCountTrade - this.orderData.HalfTonCountTrade;
        this.convertData.ThreeQuarterTonCount = this.convertData.ThreeQuarterTonCountTrade - this.orderData.ThreeQuarterTonCountTrade;
        this.convertData.OneTonServiceCount = this.convertData.OneTonServiceCountTrade - this.orderData.OneTonServiceCountTrade;
        this.convertData.OneTonFlatCount = this.convertData.OneTonFlatCountTrade - this.orderData.OneTonFlatCountTrade;
        this.convertData.HalfTonCountTrade = this.orderData.HalfTonCountTrade;
        this.convertData.ThreeQuarterTonCountTrade = this.orderData.ThreeQuarterTonCountTrade;
        this.convertData.OneTonServiceCountTrade = this.orderData.OneTonServiceCountTrade;
        this.convertData.OneTonFlatCountTrade = this.orderData.OneTonFlatCountTrade;
        await axios.post(`${constants.BASE_URL}/api/trade/on/convert`, this.convertData)
          .then(res => {
            this.confirmConvert = false;
            this.$socket.emit('transportCardsRefresh');
            this.$socket.emit('onBoardingCardsRefresh');
            this.init();
            // this.closeModal();
            this.$emit('closeModal');
          })
          .catch(err => {
            console.log(err);
            setTimeout(() => (this.error = ''), 6000);
            this.error = 'Failed to convert trade...';
          });
      } else {
        console.log('cheater');
      }
    },
    async handleVinTest() {
      const duplicates = new Set();
      const containsDuplicates = this.orderData.returningVins.some(({ vin }) => {
        if (duplicates.has(vin)) return true;
        duplicates.add(vin);
      });
      if (containsDuplicates) {
        setTimeout(() => (this.error = ''), 6000);
        this.error = 'Cannot use duplicate vins';
        this.spinners.saveChanges = false;
        return;
      }

      await axios
        .post(`${constants.BASE_URL}/api/truck/vin-test`, {
          vins: this.orderData.returningVins,
        })
        .then(res => {
          this.validatedUnits = res.data;
          this.vinsTested = true;
          this.vinsEdited = false;
        })
        .catch(err => {
          console.log(error);
        });
    },
    handleValidate() {
      // - ============ SET THE TRUCKS REMAINING ============
      this.orderData.TrucksRemaining =
        +this.orderData.HalfTonCountTrade +
        +this.orderData.ThreeQuarterTonCountTrade +
        +this.orderData.OneTonFlatCountTrade +
        +this.orderData.OneTonServiceCountTrade;
      this.orderData.TrucksRequested = this.orderData.TrucksRemaining;

      
      if (this.$route.name === 'transport') {
        if (this.orderData.TrucksRemaining !== this.vinOffset) {
          setTimeout(() => (this.error = ''), 6000);
          this.error = 'Total Returning Vins must match total for new truck types';
          return false;
        }
      } else if (this.$route.name === 'onboarding') {
        if (this.orderData.TrucksRemaining !== this.orderData.returningVins.length) {
          setTimeout(() => (this.error = ''), 6000);
          this.error = 'Total Returning Vins must match total for new truck types';
          return false;
        }
      }

      const validationData = { ...this.orderData };
      let validInformation = true;

      // * Nullable fields
      delete validationData.pickupStreetAddress2;
      delete validationData.deletedVins;
      delete validationData.pickupAltPhone;
      delete validationData.PONumber;
      delete validationData.deliveryAltPhone;
      delete validationData.deliveryStreetAddress2;
      delete validationData.useStreetAddress2;
      delete validationData.raFax;
      delete validationData.submitteeName;
      delete validationData.specialInstructions;
      delete validationData.submitDate;
      delete validationData.dateReadyForPickup;
      delete validationData.insuranceType;

      if(this.addDelivery === false) {
        delete validationData.deliveryStreetAddress;
        delete validationData.deliveryCity;
        delete validationData.deliveryState;
        delete validationData.deliveryZip;
        delete validationData.deliveryName;
        delete validationData.deliveryPhone;
        delete validationData.deliveryEmail;
      }
      Object.values(validationData).forEach((field, index) => {
        try {
          if (field.toString().trim() === '') {
            this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = 'error-box';
            validInformation = false;
            this.error = '*All fields must be filled...';
          }
        } catch (err) {
          console.log('catch error: ', err)
          this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = 'error-box';
          validInformation = false;
          this.error = '*All the fields must be filled...';
        }
      });
      setTimeout(() => (this.error = ''), 6000);
      return validInformation;
    },
    handleChange(e) {
      if (e && e.target) {
        let { name, value } = e.target;
        let oldValue = e.currentTarget
          ? e.currentTarget.getAttribute('data-originalData')
          : e.target.originalData;
        if (e.target.name == 'Company Code') {
          this.orderData.CompanyCode = ('00000' + this.orderData.CompanyCode).slice(-5);
          value = ('00000' + this.orderData.CompanyCode).slice(-5);
        } else if (e.target.name == 'Pickup Date') {
          let pickup = new Date(e.target.originalData);
          oldValue = pickup.toISOString().split('T')[0];
        } else if (e.target.name == 'Second Key') {
          value = e.target.checked;
          value = value.toString();
          oldValue = this.ogSecondKey;
        } else if (e.target.name == 'Return Ready Confirmation') {
          value = e.target.checked;
          value = value.toString();
          oldValue = this.ogConfirmedReady;
        }
        this.newRemainingTrades = 
          +this.orderData.HalfTonCountTrade +
          +this.orderData.ThreeQuarterTonCountTrade +
          +this.orderData.OneTonFlatCountTrade +
          +this.orderData.OneTonServiceCountTrade;
        
        oldValue = oldValue ? oldValue.toString() : oldValue;
        
        // - date like to run randomly so this prevent is from showing as changed
        if (oldValue == value) return delete this.orderData.changedData[name];
        
        // - normalization (delimiters not allowed)
        name = name.replace(',', '').replace('!', '');
        value = value.replace(',', '').replace('!', '');
        // - only applies to previously set values else skips
        if (oldValue != null) {
          oldValue = oldValue.replace(',', '').replace('!', '');
        }
        
        // - handles insertion into changed history hash table
        this.orderData.changedData[name] = `${name}: ${oldValue}!${name}: ${value}`;
      }
    },
    dateClickedRequested(date) {
      this.handleChange({
        target: {
          name: 'Pickup Date',
          value: date.toISOString().split('T')[0],
          originalData: this.originalData.pickupDate,
        },
      })
    },
    dateClickedReady(date) {
      this.handleChange({
        target: {
          name: 'Ready For Pickup',
          value: date.toISOString().split('T')[0],
          originalData: this.originalData.dateReadyForPickup,
        },
      })
    },
    setDamageWaiver(id) {
      this.orderData.DamageWaiverID = id;
      let name = 'Damage Waiver';
      let oldValue = this.ogDamageWaiver;
      let value = id == 1 ? 'Bronze' : id == 2 ? 'Silver' : 
                    id == 3 ? 'Gold' : id == 4 ? 'Platinum' : id == 5 ? 'Diamond' : id == 6 ? 'Full' : 'None Selected';
      this.orderData.changedData[name] = `${name}: ${oldValue}!${name}: ${value}`;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#error {
  color: #df4b5b;
}

.error-box {
  border-radius: 3px;
  border: 1px solid #df4b5b;
  max-width: 300px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 5px;
}

.edit-container {
  @include F(flex-start, center);
  width: 100%;
  margin: 5px 0;
  b {
    margin: 0 15px 0 0;
    white-space: nowrap;
  }
  input {
    flex: 1;
    border: none;
    outline: none;
    background: none;
  }
}

#edit__ttl {
  position: absolute;
  top: 0;
  font-weight: 600;
  transform: translateY(-130%);
}

.edit-container__vins {
  @include F(center, flex-start, column);
  width: 100%;
  margin: 10px 0;
  b {
    margin: 0 5px 0 0;
  }
  input {
    max-width: 90% !important;
    background: none;
    border: none;
    outline: none;
  }
}

.edit__plus {
  color: green;
  cursor: pointer;
  margin: 0 5px 0;
  font-size: 0.8rem;
}

.edit__minus {
  color: firebrick;
  cursor: pointer;
  margin: 0 5px;
  font-size: 0.8rem;
}

.edit__lock {
  color: rgb(202, 19, 19);
  margin: 0 5px;
  font-size: 0.8rem;
}

.convert_to_on_rent {
  color: gray;
  cursor: pointer;
  margin: 0 5%;
  font-size: 0.8rem;
}

.convert__lock {
  color: rgb(202, 19, 19);
  margin: 0 5%;
  font-size: 0.8rem;
}

.edit__lock--styleOverride {
  background: #db0f0f1c !important;
  border: 1px solid red;
  margin: 3px 0;
  width: 100%;
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
    width: 16%;
  }
}

.edit__lockText {
  font-weight: 700;
  text-transform: uppercase;
  color: #111;
}

.valid,
.invalid {
  font-weight: 600;
}

.valid {
  color: green;
}

.invalid {
  color: firebrick;
}

.edit__btns-container {
  margin: 10px 0 0;
  padding: 0 10px 0 0;
  @include F($j: space-between);
  width: 100%;
}

#edit__addVin {
  cursor: pointer;
  @include F();
  p {
    font-weight: 600;
  }
}

#edit__test {
  color: orangered;
  &:hover {
    text-decoration: underline;
  }
}

.edit__truckInfo {
  display: inline-block;
  margin: 3px 0;
  width: 100%;
  background: var(--edit-bg-color);
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
    width: 40%;
    margin-right: 15px;
  }
}

.convert__truckInfo {
  background: #ffc107;
  margin: 3px 0;
  width: 100%;
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
    width: 40%;
  }
}

@media screen and (min-width: 850px) {
  .edit__truckInfo-container {
    @include F(center, flex-start, column);
    margin: 0 0 20px 30px;
    width: 80%;
  }

  #edit__compInfo-container {
    @include F(flex-start, flex-start);
  }

  .edit__truckInfo {
    @include F(flex-start, center);
    input {
      width: 16%;
    }
  }

  .convert__truckInfo {
    @include F(flex-start, center);
    input {
      width: 16%;
    }
  }

  .edit__pickInfo {
    @include F(flex-start, flex-start);
    margin: 0 0 15px;
  }

#edit__pickupInfoOne-container, #edit__deliveryInfoOne-container {
  width: 50%;
}

#edit__pickupInfoTwo-container, #edit__deliveryInfoTwo-container {
  width: 50%;
}
}

.convert__are_you_sure {
  margin: 5px 15px;
  font-size: .8rem;
  font-weight: 400;
}

.convert__btn {
  margin: 5px;
}

.space-right {
  margin-right: 35px;
}

.add-delivery {
  margin-bottom: 20px;
}

.returning-vin-count {
  font-weight: 400;
  margin-left: 15px;
}

.total-new {
  margin-top: 0px;
}

.hr-total-new {
  margin-left: 0px;
  margin-right: 50%;
  margin-top: 0px;
}

.italic-bold {
  font-style: italic;
  font-weight: 500;
}

.disabled {
  cursor: no-drop;
}
</style>
