<template>
  <div>
    <div class="d-block text-center" >
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <span class="red">*</span><b>Vendor Name:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorName" @blur="checkNames()"></BInput>
        </BCol>
        <BCol sm="4" lg="3" v-if="nameError != ''"></BCol>
        <BCol sm="8" lg="9" v-if="nameError != ''" class="red left-align" style="white-space: pre;">{{ nameError }}</BCol>
        <BCol sm="4" lg="3" v-if="nameError != ''"></BCol>
        <BCol sm="8" lg="9" v-if="!saveDisabled && overrideRequired">
          <p class="red confirm-error">Similar Vendor detected, please confirm none of these are a duplicate of the new Vendor to be created.</p>
          <BRow align-h="start">
            <BCol class="override-confirm-btn">
              <BButton variant="success" @click="overrideRequired = false">Confirm</BButton>
            </BCol>
          </BRow>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <span class="red">*</span><b>Address:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorAddress"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Address 2:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorAddress2"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <span class="red">*</span><b>City:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorCity"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <span class="red">*</span><b>State:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorState"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <span class="red">*</span><b>Zip Code:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorZip"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <span class="red">*</span><b>Email:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorEmail"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Phone:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorPhone" v-mask="'(###) ###-####'" type="tel"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Mobile:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorMobile" v-mask="'(###) ###-####'" type="tel"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Website:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="VendorWebsite"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Cargo Insurance:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="CargoInsurance" type="number" min="0"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Insurance Expiration:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <datepicker
            class="datepicker"
            name="insuranceExp"
            week-start="0"
            placeholder="Select Date"
            :bootstrap-styling="true"
            v-model="InsuranceExp"
            :format="format"
            :enable-time-picker="false"
            :timezone="{ timezone: 'UTC', exactMatch: true }"
            :dark="getUserInfo.UserTheme == 'Dark' ? true : false"
            ignore-time-validation
            auto-apply
            vertical
            arrow-navigation
          ></datepicker>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Federal Id:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="FederalId"></BInput>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Entity Type:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <b-dropdown class="entity-type-drop" :text="EntityType != '' ? EntityType : 'Select Entity'" :variant="getUserInfo.UserTheme == 'Dark' ? 'dark' : 'light'">
            <b-dropdown-item v-for="(entity) in EntityOptions" :key="entity.text" @click="EntityType = entity.text">
              {{ entity.text }}
            </b-dropdown-item>
          </b-dropdown>
        </BCol>
      </BRow>
      <BRow aligh-h="between" class="vendor-row">
        <BCol sm="4" lg="3">
          <b>Primary Contact:</b>
        </BCol>
        <BCol sm="8" lg="9">
          <BInput v-model="PrimaryContact"></BInput>
        </BCol>
      </BRow>
    </div>
    <div>
      <p class="red" v-if="fieldCheck == false && missingError != ''">{{ missingError }}</p>
    </div>
    <div class="modal-option-btns">
      <BButton variant="light" size="sm" class="mt-3" @click="$emit('closeModal')">Close</BButton>
      <BButton variant="success" size="sm" class="mt-3" :disabled='saveDisabled || overrideRequired' @click="createVendor()">
        <BSpinner small v-if="spinners.submit"></BSpinner>
        Save
      </BButton>
    </div>
    <div v-if="createError !== ''">{{ createError }}</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import constants from '@/assets/constants';
// import Datepicker from 'vuejs3-datepicker';
import EventBus from '../../utils/helpers/eventBus';

export default {
  name: 'vendorModal',
  props: ['vendors'],
  components: {},
  data() {
    return {
      VendorName: '',
      VendorAddress: '',
      VendorAddress2: '',
      VendorCity: '',
      VendorState: '',
      VendorZip: '',
      VendorEmail: '',
      VendorPhone: '',
      VendorMobile: '',
      VendorWebsite: '',
      CargoInsurance: 0,
      InsuranceExp: '',
      FederalId: '',
      EntityType: '',
      VendorType: 'TRANSPORTER',
      PrimaryContact: '',
      format: 'MM/dd/yyyy',
      EntityOptions: [
        { text: 'LLC' },
        { text: 'P' },
        { text: 'S' },
        { text: 'SP' },
        { text: 'T/E' },
        { text: 'C' },
      ],
      createError: '',
      nameError: '',
      vendorList: [],
      vendorListReduced: [],
      fieldCheck: false,
      missingError: '',
      saveDisabled: false,
      overrideRequired: false,
      spinners: {
        submit: false,
      },
    };
  },
  mounted () {
    for (let i = 0; i < this.vendors.length; i++) {
      this.vendorList.push(this.vendors[i].name);
      let reducedName = this.vendors[i].name.toLowerCase();
      reducedName = reducedName.replaceAll(' ', '');
      reducedName = reducedName.replaceAll("'", "");
      reducedName = reducedName.replaceAll('&', 'and');
      reducedName = reducedName.replaceAll('.', '');
      reducedName = reducedName.replaceAll('-', '');
      reducedName = reducedName.replaceAll('llc', '');
      reducedName = reducedName.replaceAll('inc', '');
      this.vendorListReduced.push({ name: reducedName.substring(0, reducedName.length - 6), id: reducedName.slice(-6), fullname: this.vendors[i].name });
    }
  },
  computed: mapGetters(['getUserInfo']),
  methods: {
    ...mapActions(['closeModal']),
    async createVendor() {
      this.saveDisabled = true;
      this.spinners.submit = true;
      this.checkRequired();
      if (this.fieldCheck == true) {
        let vendorFields = {
          Name: this.VendorName,
          Address: this.VendorAddress,
          Address2: this.VendorAddress2,
          City: this.VendorCity,
          State: this.VendorState,
          ZipCode: this.VendorZip,
          Phone: this.VendorPhone,
          Mobile: this.VendorMobile,
          Email: this.VendorEmail,
          Website: this.VendorWebsite,
          CargoIns: parseInt(this.CargoInsurance),
          InsExp: this.InsuranceExp,
          FederalId: this.FederalId,
          EntityType: this.EntityType,
          VendorType: this.VendorType,
          Contact: this.PrimaryContact,
        }
        await axios
          .post(`${constants.BASE_URL}/api/create-vendor`, vendorFields)
          .then(res => {
            EventBus.emit('vendorCreated');
            this.$emit('closeModal');
          })
          .catch(err => {
            console.log(err);
            this.createError = err;
            let that = this;
            setTimeout(function() {
              that.createError = '';
            }, 5000);
          });
      } else {
        //saveDisabled = false;
        return;
      }
    },
    checkRequired() {
      if (this.VendorName == '' || this.VendorAddress == '' ||
          this.VendorCity == '' || this.VendorState == '' ||
          this.VendorZip == '' || this.VendorEmail == '') {
        this.missingError = 'Please fill out all required fields.';
        this.spinners.submit = false;
      } else {
        this.missingError = '';
        this.fieldCheck = true;
      }
      let that = this;
      setTimeout(function () {
        that.missingError = '';
      }, 5000);
      return;
    },
    checkNames() {
      let currentReduced = this.VendorName.toLowerCase();
      currentReduced = currentReduced.replaceAll(' ', '');
      currentReduced = currentReduced.replaceAll("'", "");
      currentReduced = currentReduced.replaceAll('&', 'and');
      currentReduced = currentReduced.replaceAll('.', '');
      currentReduced = currentReduced.replaceAll('-', '');
      currentReduced = currentReduced.replaceAll('llc', '');
      currentReduced = currentReduced.replaceAll('inc', '');
      let item = '';
      this.nameError = '';
      if (this.vendorListReduced.some(name => Object.values(name).includes(currentReduced))) {
        this.overrideRequired = true;
        let exactFind = this.vendorList.find(vendor => vendor.split(' - ')[0].toLowerCase().includes(this.VendorName.toLowerCase()));
        if (exactFind && this.VendorName.toLowerCase() == exactFind.split(' - ')[0].toLowerCase()) {
          this.saveDisabled = true;
        } else {
          this.saveDisabled = false;
        }
        // item = 'name';this.vendorList.includes(this.VendorName) || 
        let dupes = this.vendorListReduced.filter(vendor => vendor.name.includes(currentReduced));
        if (dupes.length > 1) {
          for (let i = 0; i < dupes.length; i++) {
            this.nameError += 'Vendor "' + dupes[i].fullname + '" has already been used.\r\n'
          }
        } else {
          item = dupes[0].fullname;
          this.nameError = 'Vendor "' + item + '" has already been used.'
        }
      } else {
        item = '';
        this.nameError = '';
        this.saveDisabled = false;
        this.overrideRequired = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index";

#modal__vendorModal {
  min-width: 100%;
  min-height: 100%;
  @include F($d: column);
}
.modal-option-btns {
  display: flex;
  justify-content: space-between;
}
p {
  font-size: 15px;
}

.vendor-row {
  margin-top: 10px;
}

.vendor-create-center {
  text-align: center;
  margin-bottom: 10px;
}
.entity-type-drop {
  width: 100%;
}

.entity-type-drop .btn {
  text-align: 'left'
}

.red {
  color: red;
}

.confirm-error {
  font-weight: 600;
  text-align: left;
}

.override-confirm-btn {
  text-align: left;
}

.left-align {
  text-align: left;
}

</style>
