<template>
  <div>
    <h5>Order Information:</h5>
    <div :class="orderErrors.CorporateNameEmpty" class="edit-container">
      <b>Company Name:</b>
      <input
        required
        type="text"
        v-model="orderData.CorporateName"
        name="Company Name"
        :data-originalData="originalData.CorporateName"
        @input="handleChange"
      />
    </div>
    <div id="edit__compInfo-container">
      <div id="edit__compInfoOne-wrapper">
        <div :class="orderErrors.CompanyNumberEmpty" class="edit-container">
          <b>Company Code:</b>
          <input
            required
            type="text"
            v-model="orderData.CompanyNumber"
            name="Company Number"
            :data-originalData="originalData.CompanyNumber"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.HalfTonCountEmpty" class="edit-container">
          <b>Half Ton:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.HalfTonCount"
            name="Half Tons"
            :data-originalData="originalData.HalfTonCount"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.ThreeQuarterTonCountEmpty" class="edit-container">
          <b>Three Quarter Ton:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.ThreeQuarterTonCount"
            name="Three Quarter Tons"
            :data-originalData="originalData.ThreeQuarterTonCount"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.OneTonFlatCountEmpty" class="edit-container">
          <b>One Ton Flat:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.OneTonFlatCount"
            name="One Ton Flatbeds"
            :data-originalData="originalData.OneTonFlatCount"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.OneTonServiceCountEmpty" class="edit-container">
          <b>One Ton Service:</b>
          <input
            required
            v-mask="'###'"
            type="text"
            v-model="orderData.OneTonServiceCount"
            name="One Ton Service Body's"
            :data-originalData="originalData.OneTonServiceCount"
            @input="handleChange"
          />
        </div>
      </div>
      <div id="edit__compInfoTwo-container">
        <div :class="orderErrors.EarliestDateAvailableEmpty" class="edit-container">
          <b>Earliest Date Available:</b>
          <!-- <b-form-datepicker
            size="sm"
            v-model="orderData.EarliestDateAvailable"
            @input="
              handleChange({
                target: {
                  name: 'Earliest Date Available',
                  value: orderData.EarliestDateAvailable,
                  originalData: originalData.EarliestDateAvailable,
                },
              })
            "
          ></b-form-datepicker> -->
          
          <datepicker
            v-model="orderData.EarliestDateAvailable"
            class="cust-datepicker datepicker onboard-datepicker"
            week-start="0"
            size="sm"
            :enable-time-picker="false"
            @date-update="dateClickedEarliest"
            :timezone="{ timezone: 'UTC', exactMatch: true }"
            :dark="getUserInfo.UserTheme == 'Dark' ? true : false"
            ignore-time-validation
            auto-apply
            vertical
            arrow-navigation
            required
            tabindex="4"
          ></datepicker>
        </div>
        <div :class="orderErrors.DateNeededEmpty" class="edit-container">
          <b>Date Requested:</b>
          <!-- <b-form-datepicker
            size="sm"
            v-model="orderData.DateNeeded"
            @input="
              handleChange({
                target: {
                  name: 'Date Needed',
                  value: orderData.DateNeeded,
                  originalData: originalData.DateNeeded,
                },
              })
            "
          ></b-form-datepicker> -->
          <datepicker
            v-model="orderData.DateNeeded"
            class="cust-datepicker datepicker onboard-datepicker"
            week-start="0"
            size="sm"
            :enable-time-picker="false"
            @date-update="dateClickedNeeded"
            :timezone="{ timezone: 'UTC', exactMatch: true }"
            :dark="getUserInfo.UserTheme == 'Dark' ? true : false"
            ignore-time-validation
            auto-apply
            vertical
            arrow-navigation
            required
            tabindex="4"
          ></datepicker>
        </div>
        <!-- // ! errors need to be added -->

        <div class="edit-container">
          <b>PO Number:</b>
          <input
            type="text"
            v-model="orderData.PONumber"
            name="PO Number"
            maxlength="35"
            :data-originalData="originalData.PONumber"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.ExpectedRentalLengthEmpty" class="edit-container">
          <b>Expected Rental Length:</b>
          <input
            required
            type="text"
            v-model="orderData.ExpectedRentalLength"
            name="Rental Length"
            :data-originalData="originalData.ExpectedRentalLength"
            @input="handleChange"
          />
        </div>
        <div class="edit-container">
          <b>Damage Waiver</b>
          <b-dropdown
            id="damage-waiver"
            size="sm"
            :text="orderData.DamageWaiverID == 1 ? 'Bronze' : orderData.DamageWaiverID == 2 ? 'Silver' : 
              orderData.DamageWaiverID == 3 ? 'Gold' : orderData.DamageWaiverID == 4 ? 'Platinum' : 
              orderData.DamageWaiverID == 5 ? 'Diamond' : orderData.DamageWaiverID == 6 ? 'Full' : 'None Selected'"
            variant="light"
          >
            <b-dropdown-item @click="setDamageWaiver(1)">Bronze</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(2)">Silver</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(3)">Gold</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(4)">Platinum</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(5)">Diamond</b-dropdown-item>
            <b-dropdown-item @click="setDamageWaiver(6)">Full</b-dropdown-item>
          </b-dropdown>
        </div>
        <div :class="orderErrors.SecondKeyCountEmpty" class="edit-container__key">
          <BFormCheckbox
            v-model="orderData.SecondKeyCount"
            id="key_input"
            size="sm"
            name="Second Key"
            :data-originalData="originalData.SecondKeyCount"
            @input.enter="handleChange"
          >
            <b>Second Key</b>
          </BFormCheckbox>
        </div>
      </div>
    </div>
    <h5>Delivery Information:</h5>
    <div class="edit__pickInfo">
      <div id="edit__pickupInfoOne-container">
        <div :class="orderErrors.DelNameEmpty" class="edit-container">
          <b>Name:</b>
          <input
            required
            type="text"
            v-model="orderData.DelName"
            name="Delivery Name"
            :data-originalData="originalData.DelName"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.DelPhoneEmpty" class="edit-container">
          <b>Phone:</b>
          <input
            required
            v-mask="validateDelPhone"
            type="text"
            v-model="orderData.DelPhone"
            name="Delivery Phone"
            :data-originalData="originalData.DelPhone"
            @input="handleChange"
          />
        </div>
        <div class="edit-container">
          <b>Alternate Phone:</b>
          <input
            v-mask="validateDelAltPhone"
            type="text"
            v-model="orderData.DelAltPhone"
            name="Delivery Alt Phone"
            :data-originalData="originalData.DelAltPhone"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.DelEmailEmpty" class="edit-container">
          <b>Email:</b>
          <input
            required
            type="text"
            v-model="orderData.DelEmail"
            name="Delivery Email"
            :data-originalData="originalData.DelEmail"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.DelStreetAddressEmpty" class="edit-container">
          <b>Street Address:</b>
          <input
            required
            type="text"
            v-model="orderData.DelStreetAddress"
            name="Delivery Address"
            :data-originalData="originalData.DelStreetAddress"
            @input="handleChange"
          />
        </div>
        <div class="edit-container">
          <b>Street Address 2:</b>
          <input
            required
            type="text"
            v-model="orderData.DelStreetAddress2"
            name="Delivery Address 2"
            :data-originalData="originalData.DelStreetAddress2"
            @input="handleChange"
          />
        </div>
      </div>
      <div id="edit__pickupInfoTwo-container">
        <div :class="orderErrors.DelCityEmpty" class="edit-container">
          <b>City:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.DelCity"
              name="Delivery City"
              :data-originalData="originalData.DelCity"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.DelStateEmpty" class="edit-container">
          <b>State:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.DelState"
              name="Delivery State"
              :data-originalData="originalData.DelState"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.DelZipEmpty" class="edit-container">
          <b>Zip Code:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.DelZip"
                v-mask="'#####'"
              name="Delivery Zip"
              :data-originalData="originalData.DelZip"
              @input="handleChange"
            />
          </div>
        </div>
      </div>
    </div>
    <h5>Billing Information:</h5>
    <div class="edit__pickInfo">
      <div id="edit__pickupInfoOne-container">
        <div :class="orderErrors.BillNameEmpty" class="edit-container">
          <b>Name:</b>
          <input
            required
            type="text"
            v-model="orderData.BillName"
            name="Billing Name"
            :data-originalData="originalData.BillName"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.BillPhoneEmpty" class="edit-container">
          <b>Phone:</b>
          <input
            required
            v-mask="validateBillPhone"
            type="text"
            v-model="orderData.BillPhone"
            name="Billing Phone"
            :data-originalData="originalData.BillPhone"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.BillEmailEmpty" class="edit-container">
          <b>Email:</b>
          <input
            required
            type="text"
            v-model="orderData.BillEmail"
            name="Billing Email"
            :data-originalData="originalData.BillEmail"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.BillStreetAddressEmpty" class="edit-container">
          <b>Street Address:</b>
          <input
            required
            type="text"
            v-model="orderData.BillStreetAddress"
            name="Billing Address"
            :data-originalData="originalData.BillStreetAddress"
            @input="handleChange"
          />
        </div>
        <div class="edit-container">
          <b>Street Address 2:</b>
          <input
            required
            type="text"
            v-model="orderData.BillStreetAddress2"
            name="Billing Address 2"
            :data-originalData="originalData.BillStreetAddress2"
            @input="handleChange"
          />
        </div>
      </div>
      <div id="edit__pickupInfoTwo-container">
        <div :class="orderErrors.BillCityEmpty" class="edit-container">
          <b>City:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.BillCity"
              name="Billing City"
              :data-originalData="originalData.BillCity"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.BillStateEmpty" class="edit-container">
          <b>State:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.BillState"
              name="Billing State"
              :data-originalData="originalData.BillState"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.BillZipEmpty" class="edit-container">
          <b>Zip Code:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.BillZip"
              name="Billing Zip"
              :data-originalData="originalData.BillZip"
              @input="handleChange"
            />
          </div>
        </div>
      </div>
    </div>
    <h5>Use Information:</h5>
    <div class="edit__pickInfo">
      <div id="edit__pickupInfoOne-container">
        <div :class="orderErrors.UseStreetAddressEmpty" class="edit-container">
          <b>Street Address:</b>
          <input
            required
            type="text"
            v-model="orderData.UseStreetAddress"
            name="Use Address"
            :data-originalData="originalData.UseStreetAddress"
            @input="handleChange"
          />
        </div>
        <div class="edit-container">
          <b>Street Address 2:</b>
          <input
            required
            type="text"
            v-model="orderData.UseStreetAddress2"
            name="Use Address 2"
            :data-originalData="originalData.UseStreetAddress2"
            @input="handleChange"
          />
        </div>
      </div>
      <div id="edit__pickupInfoTwo-container">
        <div :class="orderErrors.UseCityEmpty" class="edit-container">
          <b>City:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.UseCity"
              name="Use City"
              :data-originalData="originalData.UseCity"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.UseStateEmpty" class="edit-container">
          <b>State:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.UseState"
              name="Use State"
              :data-originalData="originalData.UseState"
              @input="handleChange"
            />
          </div>
        </div>
        <div :class="orderErrors.UseZipEmpty" class="edit-container">
          <b>Zip Code:</b>
          <div id="edit__CSZ">
            <input
              required
              type="text"
              v-model="orderData.UseZip"
              v-mask="'#####'"
              name="Use Zip"
              :data-originalData="originalData.UseZip"
              @input="handleChange"
            />
          </div>
        </div>
      </div>
    </div>
    <h5>Rental Information:</h5>
    <div class="edit__pickInfo">
      <div id="edit__rentalInfoOne-container">
        <div :class="orderErrors.RANameEmpty" class="edit-container">
          <b>Name:</b>
          <input
            required
            type="text"
            v-model="orderData.RAName"
            name="RA Name"
            :data-originalData="originalData.DelZip"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.RAPhoneEmpty" class="edit-container">
          <b>Phone:</b>
          <input
            required
            v-mask="validateRAPhone"
            type="text"
            v-model="orderData.RAPhone"
            name="RA Phone"
            :data-originalData="originalData.DelZip"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.RAEmailEmpty" class="edit-container">
          <b>Email:</b>
          <input
            required
            type="text"
            v-model="orderData.RAEmail"
            name="RA Email"
            :data-originalData="originalData.RAEmail"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.RAFaxEmpty" class="edit-container">
          <b>Fax:</b>
          <input type="text" v-model="orderData.RAFax" />
        </div>
      </div>
    </div>
    <p id="error">{{ error }}</p>
    <BButton v-if="!spinners.saveChanges" variant="success" @click="handleSaveChanges">Save Changes</BButton>
    <BSpinner v-if="spinners.saveChanges" id="b-spinner--add-btn__spinner"></BSpinner>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import constants from '@/assets/constants';

export default {
  name: 'editOnboarding',
  data() {
    return {
      error: '',
      spinners: {
        saveChanges: false,
      },
      originalData: {},
      orderData: {
        changedData: {},
        OrderFormID: '',
        CorporateName: '',
        CompanyNumber: '',
        HalfTonCount: 0,
        ThreeQuarterTonCount: 0,
        OneTonFlatCount: 0,
        OneTonServiceCount: 0,
        ExpectedRentalLength: '',
        DamageWaiverID: 0,
        SecondKeyCount: '',
        EarliestDateAvailable: null,
        DateNeeded: null,
        DelName: '',
        DelPhone: '',
        DelAltPhone: '',
        DelEmail: '',
        DelStreetAddress: '',
        DelStreetAddress2: '',
        DelCity: '',
        DelState: '',
        DelZip: '',
        BillName: '',
        BillPhone: '',
        BillEmail: '',
        BillStreetAddress: '',
        BillStreetAddress2: '',
        BillCity: '',
        BillState: '',
        BillZip: '',
        UseStreetAddress: '',
        UseStreetAddress2: '',
        UseCity: '',
        UseState: '',
        UseZip: '',
        RAName: '',
        RAPhone: '',
        RAEmail: '',
        RAFax: '',
        PONumber: '',
      },
      orderErrors: {
        OrderFormIDEmpty: '',
        CorporateNameEmpty: '',
        CompanyNumberEmpty: '',
        HalfTonCountEmpty: '',
        ThreeQuarterTonCountEmpty: '',
        OneTonFlatCountEmpty: '',
        OneTonServiceCountEmpty: '',
        ExpectedRentalLengthEmpty: '',
        SecondKeyCountEmpty: '',
        EarliestDateAvailableEmpty: '',
        DateNeededEmpty: '',
        DelNameEmpty: '',
        DelPhoneEmpty: '',
        DelEmailEmpty: '',
        DelStreetAddressEmpty: '',
        DelCityEmpty: '',
        DelStateEmpty: '',
        DelZipEmpty: '',
        BillNameEmpty: '',
        BillPhoneEmpty: '',
        BillEmailEmpty: '',
        BillStreetAddressEmpty: '',
        BillCityEmpty: '',
        BillStateEmpty: '',
        BillZipEmpty: '',
        UseStreetAddressEmpty: '',
        UseCityEmpty: '',
        UseStateEmpty: '',
        UseZipEmpty: '',
        RANameEmpty: '',
        RAPhoneEmpty: '',
        RAEmailEmpty: '',
        RAFaxEmpty: '',
      },
      ogDamageWaiver: '',
      ogSecondKey: 'No',
    };
  },
  computed: {
    ...mapGetters(['getCurrentNoteOrder', 'getUserInfo']),
    validateDelPhone() {
      if (this.orderData.DelPhone && (this.orderData.DelPhone.substring(0, 1) == '+' || this.orderData.DelPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validateDelAltPhone() {
      if (this.orderData.DelAltPhone && (this.orderData.DelAltPhone.substring(0, 1) == '+' || this.orderData.DelAltPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validateBillPhone() {
      if (this.orderData.BillPhone == '') {
        return '';
      } else if (this.orderData.BillPhone && (this.orderData.BillPhone.substring(0, 1) == '+' || this.orderData.BillPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validateRAPhone() {
      if (this.orderData.RAPhone == '') {
        return '';
      } else if (this.orderData.RAPhone && (this.orderData.RAPhone.substring(0, 1) == '+' || this.orderData.RAPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    }
  },
  mounted() {
    // - ============ GENERAL ============
    this.orderData.PONumber =
      this.getCurrentNoteOrder.PONumber || this.getCurrentNoteOrder.PONumberTrade;
    this.orderData.FormHistoryID = this.getCurrentNoteOrder.FormHistoryID;
    this.orderData.OrderFormID = this.getCurrentNoteOrder.OrderFormID;
    this.orderData.CorporateName = this.getCurrentNoteOrder.CorporateName;
    this.orderData.CompanyNumber =
      this.getCurrentNoteOrder.CompanyNumber || this.getCurrentNoteOrder.CompanyCode || '00000';
    this.orderData.CompanyNumber = this.orderData.CompanyNumber.toString().padStart(5, '0');
    this.orderData.TrucksRequested = this.getCurrentNoteOrder.TrucksRequested;
    this.orderData.HalfTonCount = this.getCurrentNoteOrder.HalfTonCount;
    this.orderData.ThreeQuarterTonCount = this.getCurrentNoteOrder.ThreeQuarterTonCount;
    this.orderData.OneTonFlatCount = this.getCurrentNoteOrder.OneTonFlatCount;
    this.orderData.OneTonServiceCount = this.getCurrentNoteOrder.OneTonServiceCount;
    this.orderData.ExpectedRentalLength = this.getCurrentNoteOrder.ExpectedRentalLength;
    // Damage Waiver will default to 1 if order doesn't have one selected previously
    this.orderData.DamageWaiverID = this.getCurrentNoteOrder.DamageWaiverID || 1;
    this.ogDamageWaiver = this.orderData.DamageWaiverID == 1 ? 'Bronze' : this.orderData.DamageWaiverID == 2 ? 'Silver' : 
      this.orderData.DamageWaiverID == 3 ? 'Gold' : this.orderData.DamageWaiverID == 4 ? 'Platinum' : 
      this.orderData.DamageWaiverID == 5 ? 'Diamond' : this.orderData.DamageWaiverID == 6 ? 'Full' : 'None Selected';
    this.orderData.SecondKeyCount = !!this.getCurrentNoteOrder.SecondKeyCount;
    this.ogSecondKey = this.orderData.SecondKeyCount;
    this.orderData.EarliestDateAvailable = this.getCurrentNoteOrder.EarliestDateAvailable;
    this.orderData.DateNeeded = this.getCurrentNoteOrder.DateNeeded;
    // - ============ DELIVERY ============
    this.orderData.DelName = this.getCurrentNoteOrder.DelName || this.getCurrentNoteOrder.Name;
    this.orderData.DelPhone = this.getCurrentNoteOrder.DelPhone || this.getCurrentNoteOrder.Phone;
    this.orderData.DelAltPhone = this.getCurrentNoteOrder.DelAltPhone || this.getCurrentNoteOrder.AltPhone;
    this.orderData.DelEmail = this.getCurrentNoteOrder.DelEmail || this.getCurrentNoteOrder.Email;
    this.orderData.DelStreetAddress =
      this.getCurrentNoteOrder.DelStreetAddress || this.getCurrentNoteOrder.StreetAddress;
    this.orderData.DelStreetAddress2 = this.getCurrentNoteOrder.DelStreetAddress2 || this.getCurrentNoteOrder.Street2;
    this.orderData.DelCity = this.getCurrentNoteOrder.DelCity || this.getCurrentNoteOrder.City;
    this.orderData.DelState = this.getCurrentNoteOrder.DelState || this.getCurrentNoteOrder.State;
    this.orderData.DelZip = this.getCurrentNoteOrder.DelZip || this.getCurrentNoteOrder.Zip;
    // - ============ BILLING ============
    this.orderData.BillName = this.getCurrentNoteOrder.BillName;
    this.orderData.BillPhone = this.getCurrentNoteOrder.BillPhone;
    this.orderData.BillEmail = this.getCurrentNoteOrder.BillEmail;
    this.orderData.BillStreetAddress = this.getCurrentNoteOrder.BillStreetAddress;
    this.orderData.BillStreetAddress2 = this.getCurrentNoteOrder.BillStreetAddress2;
    this.orderData.BillCity = this.getCurrentNoteOrder.BillCity;
    this.orderData.BillState = this.getCurrentNoteOrder.BillState;
    this.orderData.BillZip = this.getCurrentNoteOrder.BillZip;
    // - =============== Use =================
    this.orderData.UseStreetAddress = this.getCurrentNoteOrder.UseStreetAddress;
    this.orderData.UseStreetAddress2 = this.getCurrentNoteOrder.UseStreetAddress2;
    this.orderData.UseCity = this.getCurrentNoteOrder.UseCity;
    this.orderData.UseState = this.getCurrentNoteOrder.UseState;
    this.orderData.UseZip = this.getCurrentNoteOrder.UseZip;
    // - ============ RENTAL INFO ============
    this.orderData.RAName = this.getCurrentNoteOrder.RAName;
    this.orderData.RAPhone = this.getCurrentNoteOrder.RAPhone;
    this.orderData.RAEmail = this.getCurrentNoteOrder.RAEmail;
    this.orderData.RAFax = this.getCurrentNoteOrder.RAFax;

    this.originalData = { ...this.orderData };
  },
  methods: {
    ...mapActions(['closeModal']),
    handleValidate() {
      // - ============ SET THE TRUCKS REMAINING ============
      this.orderData.TrucksRequested =
        +this.orderData.HalfTonCount +
        +this.orderData.ThreeQuarterTonCount +
        +this.orderData.OneTonFlatCount +
        +this.orderData.OneTonServiceCount;

      /*
        todo matt: delete this when we are sure that this is working

        Cases:
          *Subtract the assigned value if exiting*
          Transport edits Count
          *Save the remaining*
          Transport just saves

          ?NumTrucks (original count)
          ?TrucksRemaining (remaining)
          ?ReturnTrucksRemaining (empty)

          *Subtract the assigned value if exiting*
          Onboarding edit the count
          *Save the remaining*
          Onboarding just saves

          ?TrucksRequested
          ?TrucksRemaining

        plan:
          we can just always subtract he differece from truck requested (only thing is the variable) names will change

        issues:
          don't let the user go past negative (new amount - already assigned)

        */

      let assignedTruckCount = this.getCurrentNoteOrder.NumTrucks
        ? this.getCurrentNoteOrder.NumTrucks - this.getCurrentNoteOrder.TrucksRemaining
        : this.getCurrentNoteOrder.TrucksRequested - this.getCurrentNoteOrder.TrucksRemaining;

      this.orderData.TrucksRemaining = this.orderData.TrucksRequested - assignedTruckCount;

      if (this.orderData.TrucksRemaining < 0) {
        this.error = `*You cannot remove more trucks than have already been assigned a carrier (${assignedTruckCount} assigned)...`;
        return false;
      }

      // todo matt: delete this when we are sure that this is working
      // console.log(
      //   'assigned count:',
      //   assignedTruckCount,
      //   'trucksRemaining:',
      //   this.orderData.TrucksRemaining,
      //   'trucksRequested',
      //   this.orderData.TrucksRequested
      // );

      const validationData = { ...this.orderData };
      let validInformation = true;

      const errorIndex = -1;
      // * Nullable fields
      delete validationData.RAFax;
      delete validationData.DelStreetAddress2;
      delete validationData.BillStreetAddress2;
      delete validationData.UseStreetAddress2;
      delete validationData.PONumber;
      delete validationData.DelAltPhone;
      delete validationData.PickupAltPhone;
      delete validationData.EarliestDateAvailable;
      Object.values(validationData).forEach((field, index) => {
        try {
          if (field.toString().trim() === '') {
            this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = 'error-box';
            validInformation = false;
            this.error = '*All fields must be filled...';
          }
        } catch (err) {
          this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = 'error-box';
          validInformation = false;
          this.error = '*All fields must be filled...';
        }
      });
      return validInformation;
    },
    async handleSaveChanges() {
      this.spinners.saveChanges = true;
      if (!(this.handleValidate())) {
        this.spinners.saveChanges = false;
        return;
      }
      axios
        .put(`${constants.BASE_URL}/api/order/edit`, this.orderData)
        .then(res => {
          this.spinners.saveChanges = false;
          if (this.$route.name === 'transport') {
            this.$socket.emit('transportCardsRefresh');
            this.$socket.emit('onBoardingCardsRefresh');
          } else {
            this.$socket.emit('onBoardingCardsRefresh');
          }
          // this.closeModal();
          this.$emit('closeModal');
        })
        .catch(err => {
          setTimeout(() => (this.error = ''), 5000);
          this.error = '*All fields must be filled...';
          console.log(err);
        });
    },

    handleChange(e) {
      if (e) {
        let { name, value } = e.target;
        let oldValue = e.currentTarget
          ? e.currentTarget.getAttribute('data-originalData')
          : e.target.originalData;
        if (!oldValue) {
          oldValue = 'NA';
        }
        if (e.target.name == 'Company Number') {
          this.orderData.CompanyNumber = ('00000' + this.orderData.CompanyNumber).slice(-5);
          value = ('00000' + this.orderData.CompanyNumber).slice(-5);
        } else if (e.target.name == 'Second Key') {
          value = e.target.checked;
          value = value.toString();
          oldValue = this.ogSecondKey;
        } 
        
        oldValue = oldValue.toString();
        
        if (oldValue === value) return delete this.orderData.changedData[name];
        name = name.replace(',', '').replace('!', '');
        value = value.replace(',', '').replace('!', '');
        if (oldValue !== null) {
          oldValue = oldValue.replace(',', '').replace('!', '');
        }
        if (oldValue != value) {
          this.orderData.changedData[name] = `${name}: ${oldValue}!${name}: ${value}`;
        }
      }
    },
    dateClickedEarliest(date) {
      this.handleChange({
        target: {
          name: 'Earliest Date Available',
          value: date.toISOString().split('T')[0],
          originalData: this.originalData.EarliestDateAvailable,
        },
      })
    },
    dateClickedNeeded(date) {
      this.handleChange({
        target: {
          name: 'Date Needed',
          value: date.toISOString().split('T')[0],
          originalData: this.originalData.DateNeeded,
        },
      })
    },
    setDamageWaiver(id) {
      this.orderData.DamageWaiverID = id;
      let name = 'Damage Waiver';
      let oldValue = this.ogDamageWaiver;
      let value = id == 1 ? 'Bronze' : id == 2 ? 'Silver' : 
        id == 3 ? 'Gold' : id == 4 ? 'Platinum' : id == 5 ? 'Diamond' : id == 6 ? 'Full' : 'None Selected';
      if (oldValue != value) {
        this.orderData.changedData[name] = `${name}: ${oldValue}!${name}: ${value}`;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index';

h5 {
  margin: 10px 0 0;
}

#error {
  color: #df4b5b;
  padding: 5px;
}

.error-box {
  border-radius: 3px;
  border: 1px solid #df4b5b;
  max-width: 300px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 35px;
}

.edit-container {
  @include F(flex-start, center);
  width: 100%;
  margin: 5px 5px;
  b {
    margin: 0 15px 0 0;
    white-space: nowrap;
  }
  input {
    flex: 1;
    border: none;
    outline: none;
    background: none;
  }
}

#edit__rentalInfoOne-container {
  width: 90%;
}

.edit-container__key {
  @include F(flex-start, flex-start);
}

#edit__ttl {
  position: absolute;
  top: 0;
  font-weight: 600;
  transform: translateY(-130%);
}

.edit-container__vins {
  @include F(center, flex-start, column);
  width: 100%;
  margin: 10px 0;
  b {
    margin: 0 5px 0 0;
  }
  input {
    max-width: 90% !important;
    background: none;
    border: none;
    outline: none;
  }
}

.edit__plus {
  color: green;
  cursor: pointer;
  margin: 0 5px 0;
  font-size: 0.8rem;
}

.edit__minus {
  color: firebrick;
  cursor: pointer;
  margin: 0 5px;
  font-size: 0.8rem;
}

#edit__addVin {
  cursor: pointer;
  @include F();
  margin: 10px 0 0;
  p {
    font-weight: 600;
  }
}

.onboard-datepicker {
  min-width: 150px;
  max-width: 225px;
}

@media screen and (min-width: 850px) { 
  .edit__truckInfo-container {
    @include F(center, flex-start, column);
    margin: 0 0 20px 30px;
  }
  #edit__compInfo-container {
    @include F(flex-start, flex-start);
  }
  .edit__truckInfo {
    @include F(flex-start, center);
    background: var(--edit-bg-color);
    margin: 3px 0;
    b {
      font-weight: 700;
    }
    input {
      font-size: 0.8rem;
      width: 16%;
    }
  }
  .edit__pickInfo {
    @include F(flex-start, flex-start);
    margin: 0 0 15px;
  }
}


</style>
